<template>
  <div>
    <div class="hidden lg:w-64 w-64 w-1/3 lg:w-40 lg:w-1/3 w-1/2 lg:w-1/2 w-full"></div>
    <div class="fixed top-8 md:top-28 mx-8 md:mx-0 md:right-8 z-50">
      <Toast
        v-for="toast in toasts"
        v-bind:key="toast.toast"
        :toast="toast.toast"
        :position="toast.position"
        :type="toast.type"
        :vars="toast.vars"
        class="mb-3"
      />
    </div>
    <GpError />
    <transition name="fade" mode="out-in">
      <div
        v-if="loading"
        class="
          fixed
          p-12
          rounded-3xl
          bg-white
          left-1/2
          top-1/2
          -translate-y-1/2 -translate-x-1/2
          transform
          z-50
          shadow-md
        "
      >
        <Loading />
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <ImpactCardExpanded />
    <ShareImage />
    <!-- <BugReport v-if="!noNav.includes(route.name)" /> -->
    <popup
      :title="popup.title"
      :icon="popup.icon"
      :align="popup.align"
      :show="showPopup"
      :side="popup.side"
      :file="popup.file"
      :icon-bg="popup.iconBg"
      :primaryButton="popup.primaryButton"
      :primaryLink="popup.primaryLink"
      :secondaryButton="popup.secondaryButton"
      :secondaryLink="popup.secondaryLink"
      :disableClose="popup.disableClose"
      :docFormat="popup.docFormat"
      :bgColor="popup.bgColor"
      @close="clearPopup"
      :class="{ 'pointer-events-none': !showPopup }"
    >
      <template v-slot:content>
        <div v-html="popup.content"></div>
      </template>
      <template v-slot:component>
        <component :is="popup.component" v-bind="popup.props" />
      </template>
    </popup>
    <Navbar
      v-if="!noNav.includes(route.name)"
      :show="!noNav.includes(route.name)"
    />
     <AutoLogout v-if="!noAutoLogout.includes(route.name)" />
    </div>
</template>

<script>
import Navbar from '@/components/general/Navbar'
import Popup from '@/components/general/Popup'
import Toast from '@/components/general/Toast'
import GpError from '@/components/general/GpError'
import TopupBankwire from '@/components/popup/TopupBankwire'
import CancelAutoInvestmentPopup from '@/components/popup/CancelAutoInvestmentPopup'
import ConfirmAmountTopUp from '@/components/popup/ConfirmAmountTopUp'
import WalletInfo from '@/components/popup/WalletInfo'
import RiskWarning from '@/components/popup/RiskPopup'
import LowRiskWarning from '@/components/popup/LowRiskWarning'
import ConcentrationWarning from '@/components/popup/ConcentrationWarning'
import LendWarning from '@/components/popup/LendPopup'
import LengthWarning from '@/components/popup/LengthWarning'
import DashboardPopup from '@/components/popup/DashboardPopup'
import ConnectBank from '@/components/popup/ConnectBank'
import GoodPayin from '@/components/popup/GoodPayin'
import GoodPayout from '@/components/popup/GoodPayout'
import Activate from '@/components/popup/Activate'
import ReplaceBankAccount from '@/components/popup/ReplaceBankAccount'
import UnsuitableWarning from '@/components/popup/UnsuitableWarning'
import WrongTimezone from '@/components/popup/WrongTimezone'
import PersonalPopup from '@/components/popup/PersonalPopup'
import IsUbo from '@/components/popup/IsUbo'
import AutoLogoutWarning from '@/components/popup/AutoLogoutWarning'
import ContractFiltersDrawer from '@/components/contracts/ContractFiltersDrawer.vue'
import TransactionsFiltersDrawer from '@/components/transactions/TransactionsFiltersDrawer.vue'
import ImpactCardExpanded from '@/components/impact/impact/CardExpanded'
import {
  computed,
  ref,
  defineAsyncComponent,
  onMounted,
  onUnmounted
} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from "vue-router";
import ShareImage from '@/components/general/ShareImage'
import CardExpanded from '@/components/impact/impact/CardExpanded'
// import BugReport from '@/components/general/BugReport'
import Loading from '@/components/general/Loading'

export default {
  components: {
    Loading,
    // BugReport,
    IsUbo,
    CardExpanded,
    ShareImage,
    Toast,
    Navbar,
    Popup,
    ImpactCardExpanded,
    TopupBankwire,
    GpError,
    WalletInfo,
    CancelAutoInvestmentPopup,
    ConfirmAmountTopUp,
    ConcentrationWarning,
    LowRiskWarning,
    RiskWarning,
    LendWarning,
    LengthWarning,
    UnsuitableWarning,
    PersonalPopup,
    Activate,
    ReplaceBankAccount,
    DashboardPopup,
    ConnectBank,
    GoodPayin,
    GoodPayout,
    AutoLogoutWarning,
    AutoLogout: defineAsyncComponent(() =>
      import('@/components/general/AutoLogout')
    ),
    WrongTimezone,
    ContractFiltersDrawer,
    TransactionsFiltersDrawer
  },
  setup() {
    const noNav = ref([
      'login',
      'signup',
      'onboarding',
      'password-forgot',
      'password-reset',
      'signupvoucher',
      'signup-company',
      'not-found',
      'maintenance',
      'unauthorized'
    ])
    const noAutoLogout = ref([
      'login',
      'signup',
      'password-forgot',
      'password-reset',
      'signupvoucher',
      'not-found',
      'signup-company',
      'maintenance',
      'unauthorized',
      'activate'
    ])
    const store = useStore()
    const route = useRoute()
    const popup = computed(() => {
      return store.state.general.popup
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const loading = computed(() => {
      return store.state.general.loading
    })

    const toasts = computed(() => {
      return store.getters.getToasts()
    })

    const showPopup = computed(() => {
      return store.state.general.showPopup
    })

    function clearPopup() {
      store.dispatch('setShowPopup', false)
    }

    function checkTabPress(e) {
      'use strict'
      var ele = document.activeElement

      if (e.keyCode === 9) {
        if (ele.nodeName === 'BODY' || ele.nodeName === 'BUTTON')
          e.preventDefault()
        const nofocus =
          ele.getAttribute('nofocus') === 'true' ||
          (e.path && e.path[2] && e.path[2].getAttribute('nofocus') === 'true')
        if (nofocus) e.preventDefault()
      }
    }

    onMounted(() => {
      document.addEventListener(
        'keydown',
        function (e) {
          checkTabPress(e)
        },
        false
      )
    })

    onUnmounted(() => {
      document.removeEventListener(
        'keydown',
        function (e) {
          checkTabPress(e)
        },
        false
      )
    })

    return {
      route,
      loading,
      profile,
      noNav,
      popup,
      showPopup,
      toasts,
      clearPopup,
      noAutoLogout
    }
  }
}
</script>

<style lang="postcss">
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #273441;
  @apply bg-grey-ghost lg:bg-green-8;
}
</style>
