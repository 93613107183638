export default function getCanadianCities() {
    return [
      'Halifax',
      'Glace_Bay',
      'Moncton',
      'Goose_Bay',
      'Blanc-Sablon',
      'Toronto',
      'Nipigon',
      'Thunder_Bay',
      'Iqaluit',
      'Pangnirtung',
      'Atikokan',
      'Winnipeg',
      'Rainy_River',
      'Resolute',
      'Rankin_Inlet',
      'Regina',
      'Swift_Current',
      'Edmonton',
      'Cambridge_Bay',
      'Yellowknife',
      'Inuvik',
      'Creston',
      'Dawson_Creek',
      'Fort_Nelson',
      'Vancouver',
      'Whitehorse',
      'Dawson',
      'St_Johns',
      'Montreal',
      'New_York',
      'Detroit',
      'Denver',
      'Chicago',
      'Boise',
      'Indianapolis',
      'Los_Angeles',
      'Phoenix',
      'Anchorage',
      'Juneau'
    ]
  }
  