<template>
  <div class="signup rounded-5xl px-14 py-10 sm:bg-white sm:flex sm:items-start sm:shadow-card">
    <div class="m-auto h-5/6 w-100 md:w-full flex flex-col items-center sm:items-start">
    <div v-if="voucher && campaignValue"
      class="sm:hidden font-medium no-highlight text-grey-3 w-full mb-4"
    >
      <div
        class="font-medium text-2xl md:text-4xl text-grey-1 mb-1 md:mb-4"
        v-html="$t('signup.slider_voucher[0].title', {campaign_value:campaignValue})"
      />
      <div
        class="text-sm text-grey-1 mb-4 md:mb-9"
        
      >
        {{ $t('signup.slider_voucher[0].subtitle', {campaign_value: campaignValue}) }}
      </div>
    </div>
    <div>
      <div v-if="voucher === null" class="text-2xl font-bold text-grey-2 text-left flex mb-6">
        {{ $t('signup.title') }}
      </div>
      <div
        class="text-grey-2 font-medium text-sm text-left -mt-4 mb-6"
      >
        <span class="mr-1">
          {{ $t('signup.already_have_account') }}
        </span>
        <router-link
          class="orange-link"
          to="/login"
          >{{ $t('wording.login') }}</router-link>.
        </div>
      </div>
      <div class="relative w-96 md:w-full flex flex-col items-center justify-center">
        <div v-if="voucher" class="text-2xl font-bold text-grey-2 text-left flex hidden sm:block">
          {{ $t('signup.title') }}
        </div>
        <Form @submit="register" v-slot="{ errors, meta }" class="relative h-5/6">
          <GeneralInput
            v-model:data="email"
            :triedToSubmit="triedToSubmit"
            type="email"
            name="email"
            :label="company ? $t('signup.work_email') : 'Email'"
            bgColor= 'bg-grey-ghost'
            rules="required|email"
            :error="errors.email"
          />
          <GeneralInput
            v-model:data="password"
            :triedToSubmit="triedToSubmit"
            type="password"
            name="password"
            label="Password"
            bgColor='bg-grey-ghost'
            :signupPassword="true"
            :error="errors.password"
            rules="required|minLength:8|hasUpperLower|hasNumberSymbol|maxLength:24"
          />
          <GpButton 
            id="gp-button-welcome-register-with-email-2"
            submit
            :disabled="loading || !meta.valid"
            class="mb-2 text-13 mt-12 "
            desktopWidth="w-full"
          >
            {{ !company ? $t('signup.button') : $t('signup.button_company') }}
          </GpButton>

          <div class="text-center mt-4 text-grey-3 text-sm relative">
            <span class="mr-1"
              >{{
                !company
                  ? $t('signup.by_signing_up')
                  : $t('signup.by_signing_up_company')
              }}
            </span>
            <br class="block sm:hidden" />
            <a
              class="orange-link mr-1"
              :href='terms'
              target="_blank"
              >{{ $t('signup.terms') }}</a
            >
            <span class="mr-1"> {{ $t('wording.and') }} </span>
            <a
              class="orange-link"
              :href='privacy'
              target="_blank"
              >{{ $t('signup.privacy') }}</a
            >.
          </div>
        </Form>
      </div>
      <div class="text-grey-2">
        <span class="opacity-60 mr-1">
          {{ $t('signup.already_have_account') }}
        </span>
        <router-link class="orange-link" :to="'login'">{{
          $t('wording.login')
        }}</router-link>.
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import GeneralInput from '../general/Input.vue'
import GpButton from '@/components/general/GpButton.vue'
import { Form } from 'vee-validate'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    GeneralInput,
    Form,
    GpButton,
  },
  props: {
    company: {
      type: Boolean,
      default: false
    },
    voucher: {
      type: String,
      default: null
    },
    campaignValue: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n({ useScope: 'global' })
    const { locale } = useI18n({ useScope: 'global' })

    let password = ref('')
    let email = ref('')
    let triedToSubmit = ref(false)
    let error = ref(null)

    const loading = computed(() => {
      return store.state.general.loading
    })

    const serverUrl = computed(() => {
			return process.env.VUE_APP_SERVER_BASE_URL ?? null
		})

    function register() {
      triedToSubmit.value = true
      error.value = null
      store.dispatch('setLoading', true)
      store
        .dispatch('signup', {
          email: email.value,
          password: password.value,
          password_confirmation: password.value,
          name: route.params.voucher + '@' + locale.value,
          surname:
            (props.company ? 'company@' : 'particular@') +
            route.query.click_id
        })
        .then(() => {
          store.dispatch('setLoading', false)
          router.push({
            name: 'onboarding',
            query: { step: 'registration' }
          })
        const popup = {
          title: i18n.t('signup.popup.title'),
          align: 'center',
          side: false,
          drawer: false,
          icon: '/img/signup/email.svg',
          content: i18n.t('signup.popup.content'),
          primaryButton: i18n.t('signup.popup.btn')
        }
        store.dispatch('setPopup', popup)
      })
      .catch((err) => {
        store.dispatch('setLoading', false)
        error.value = err
        store.dispatch('setError', err)
      })
    
    }

    return {
      register,
      email,
      serverUrl,
      password,
      error,
      triedToSubmit,
      locale,
      loading,
      terms: process.env.VUE_APP_TERMS_LINK,
      privacy: process.env.VUE_APP_PRIVACY_LINK
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .card {
    padding-top: 0rem;
  }
}

@media only screen and (min-width: 767px) {
  .card {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

</style>
