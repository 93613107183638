<template>
  <div class="relative w-full">
    <div class="hidden">
      <span
        class="bg-green-lime bg-green-mint bg-pink-raspberry bg-blue-night"
      ></span>
      <div id="popup-card">
        <div class="text-sm font-medium text-grey-3">
          {{ $t('impact.card.scale.popup.description') }}
        </div>
        <div
          v-for="item in $tm('impact.card.scale.popup.list')"
          v-bind:key="item.title"
          class="mt-4 mb-4"
        >
          <div class="mb-1 text-grey-2 text-sm font-bold">{{ item.title }}</div>
          <div class="text-grey-3 text-13">{{ item.description }}</div>
        </div>
      </div>
    </div>
    <div class="sm:absolute bottom-14 text-13 text-grey-4 flex pb-1 md:pb-6">
      {{ $t('impact.card.scale.subtitle') }}
      <img
        v-if="!noAnimation"
        src="@/assets/svg/info.svg"
        alt=""
        class="cursor-pointer z-60 ml-1"
        @click="openPopup"
      />
    </div>
    <div v-if="level!==0" class="md:absolute bottom-14 text-13 text-grey-4 flex">
      <div v-if="isMobile">
        {{ $t('impact.card.scale.title', { plant: levels.toReversed()[level-1] }) }}
      </div>
      <div v-else>
        {{ $t('impact.card.scale.title', { plant: levels[level-1] }) }}
      </div>
    </div>
    <div
      class="
        h-0.5
        absolute
        mt-0.5
        md:bottom-10
        transform
        -rotate-90
        md:rotate-0
        origin-left
        left-1
        bottom-1
      "
      style="background: #cbe1d4"
      :style="
        'width: ' +
        (isMobile
          ? scaleLevels.clientHeight - 32 + 'px'
          : 100 - (1 / levels.length) * 100 + '%')
      "
    ></div>
    <div
      class="
        h-1
        absolute
        mt-0.5
        bg-green-2
        fill
        transform
        -rotate-90
        md:rotate-0
        origin-left
        left-1
        bottom-1
        md:bottom-9.5
      "
      :style="
        'width: ' +
        (isMobile
          ? scaleLevels.clientHeight * ((dataLevel - 1) / levels.length) + 'px'
          : ((dataLevel - 1) / levels.length) * 100 + '%;')
      "
    ></div>
    <div ref="scaleLevels">
      <div
        v-for="(lvl, index) in levels"
        v-bind:key="lvl + '-' + index"
        class="text-grey-4 text-13 pt-5 md:absolute bottom-0 flex items-center"
        :style="'left:' + (index / levels.length) * 100 + '%'"
      >
        <div
          class="
            w-2
            h-2
            rounded-full
            md:mb-4
            ease-out
            bottom-4
            md:bottom-5 md:absolute
            transform
            delay-500
            mr-4
            md:mr-0
          "
          :class="{
            'scale-250':
              dataLevel ===
                (isMobile ? levels.length - 1 - index : index) + 1 &&
              !noAnimation,
            'w-6 h-6 -top-3':
              dataLevel ===
                (isMobile ? levels.length - 1 - index : index) + 1 &&
              noAnimation,
            'bg-green-2':
              dataLevel > (isMobile ? levels.length - 1 - index : index),
            'bg-green-9':
              dataLevel < (isMobile ? levels.length - 1 - index : index) + 1,
            'transition-all': !noAnimation
          }"
        >
          <img
            src="@/assets/svg/impact/brocoli.svg"
            alt=""
            class="absolute transform scale-0 delay-500 ease-out"
            :class="{
              'scale-60':
                dataLevel === (isMobile ? levels.length - 1 - index : index) + 1
            }"
          />
        </div>
        <div
          class="transition-all delay-500"
          :class="{
            'text-grey-1 font-bold':
              dataLevel === (isMobile ? levels.length - 1 - index : index) + 1,
            'text-grey-2':
              dataLevel > (isMobile ? levels.length - 1 - index : index)
          }"
        >
          {{ lvl }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, watch, ref, computed, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  components: {},
  props: {
    level: {
      type: Number,
      default: 0
    },
    noAnimation: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const i18n = useI18n()
    const levelsArray = ref(i18n.tm('impact.card.scale.popup.list').map(a => a.title).reverse())
    const scaleLevels = ref({})
    const dataLevel = ref(0)
    const store = useStore()
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const innerWidth = ref(window.innerWidth)

    const levels = computed(() => {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return isMobile.value ? levelsArray.value.reverse() : levelsArray.value
    })

    const isMobile = computed(() => {
      return innerWidth.value < 768
    })

    onMounted(async () => {
      window.addEventListener('resize', handleResize)
      const timer = (ms) => new Promise((res) => setTimeout(res, ms))
      async function load() {
        // We need to wrap the loop into an async function for this to work
        for (var i = 1; i < props.level + 1; i++) {
          dataLevel.value = i
          await timer(1000) // then the created Promise can be awaited
        }
      }
      if (!props.noAnimation) {
        await timer(500) // then the created Promise can be awaited
        load()
      } else {
        dataLevel.value = props.level
      }
    })

    watch(
      () => props.level,
      () => {
        dataLevel.value = props.level
      }
    )

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    function handleResize() {
      innerWidth.value = window.innerWidth
    }

    function openPopup() {
      const content = document.getElementById('popup-card')
      const popup = {
        title: i18n.t('impact.card.scale.popup.title'),
        align: 'left',
        side: true,
        content: content.outerHTML
      }
      store.dispatch('setPopup', popup)
    }

    return { profile, levels, dataLevel, openPopup, isMobile, scaleLevels }
  }
}
</script>

<style lang="css" scoped>
.ball {
  transform: scale(0);
  animation: scale 1s;
  animation-fill-mode: forwards;
  background: rgb(79, 89, 99);
  background: linear-gradient(
    180deg,
    rgba(79, 89, 99, 0.1) 0%,
    rgba(0, 212, 255, 0) 100%
  );
}
.fill {
  width: 0px;
  transition: width 0.5s ease-out;
}
</style>
