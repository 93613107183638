<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        class="page flex px-0 lg:px-0 container overflow-y-hidden"
        style="padding-bottom: 0"
      >
        <SignupVoucher
          class="block md:hidden"
          :show="showSignupVoucher"
          @hide="showSignupVoucher = false"
        />
        <div class="absolute sm:ml-0 items-center md:items-baseline pt-4 lg:ml-16 sm:pt-14 z-50 flex w-full sm:w-auto justify-between md:justify-start">
          <a :href="url" >
            <img
              src="@/assets/img/logo/goparity.svg"
              alt=""
              class="w-28 sm:w-40 ml-4 sm:ml-0"
            />
          </a>
        </div>
        <div class="sm:relative lg:flex overflow-hidden lg:flex-grow w-full">
          <img
            src="@/assets/img/plant-hand.png"
            alt=""
            class="absolute right-0 mt-12 block sm:hidden"
          />
          <GeneralSlide
            class="
              lg:w-6/12 lg:m-auto lg:h-screen
              text-left
              hidden
              lg:block
              items-end
              lg:items-start
            "
            :dir="'/img/signup/'"
            :data="data"
          />
          <SignupCard
            class="
              w-full
              bottom-0
              min-h-screen-2/3
              sm:h-auto
              lg:min-h-3/4 lg:min-h-auto
              sm:relative
              absolute
              lg:w-6/12
              xl:w-5/12
              2xl:w-4/12
              top-card
              sm:top-0
              h-fit
              max-w-3xl
              lg:mt-0 lg:mt-auto
              md:m-auto
              lg:scale-90
              transform
            "
            :voucher="voucher"
            :campaignValue="campaignValue"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SignupCard from '@/components/signup/Card.vue'
import GeneralSlide from '@/components/general/Slide.vue'
import SignupVoucher from '@/components/signup/SignupVoucher.vue'
import { computed, ref, onMounted } from 'vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { useStore } from 'vuex'

export default {
  components: {
    SignupCard,
    SignupVoucher,
    GeneralSlide
  },
  setup() {
    const { isCanadianTimezone } = useGlobalHelpers()
    const route = useRoute()
    const i18n = useI18n()
    const showSignupVoucher = ref(true)
    const { dollarFormatting } = useGlobalHelpers()
    const loaded = ref(false)
    const store = useStore()
    let campaignValue = ref(dollarFormatting(500))

    const voucher = computed(() => {
      return route.params.voucher
    })

    const url = computed(() => {
      return process.env.VUE_APP_FE_BASE_URL
    })

    onMounted(async () => {
      if(!isCanadianTimezone()) {
        const popup = {
          component: 'WrongTimezone',
          disableClose: true,
          side: false
          }
          store.dispatch('setPopup', popup)
      }

      if(voucher.value) {
        await axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/campaigns/voucher/' + voucher.value)
        .then(({data}) => {
          campaignValue.value = dollarFormatting(data.value)
        }).catch((e) => {
          const popup = {
            title: i18n.t('signup.no_voucher_found_popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-orange-5',
            icon: '/img/popup/exclamation_orange.svg',
            content: i18n.t('signup.no_voucher_found_popup.description'),
            primaryButton: i18n.t('signup.no_voucher_found_popup.btn'),
            primaryLink: "/signup"
          }
          store.dispatch('setPopup', popup)
        })
      }
      loaded.value = true
    })

    const data = computed(() => {
      const arr = i18n.tm('signup.slider')

      return arr;
    })
    return { voucher, data, showSignupVoucher, url, campaignValue }
  }
}
</script>
