import axios from 'axios'

export default {
  state: {
    transactions: null,
    transactionSample: null,
    transaction: null,
    lastTopup: null,
    filters: {
      categories: {},
      date: {
        from: null,
        to: null
      },
      wallet: null
    },
    requestFilters: {
      categories: [],
      date: {
        from: null,
        to: null
      },
      wallet: null
    },
    nextPage: 1,
    pendingVouchers: null
  },
  mutations: {
    SET_TRANSACTIONS(state, data) {
      if (state.nextPage === 1) {
        state.transactions = data.transactions
      } else {
        state.transactions = Object.assign(
          state.transactions,
          data.transactions
        )
      }
      state.nextPage = data.nextPage
    },
    SET_SAMPLE_TRANSACTIONS(state, data) {
      state.transactionSample = data.transactions
    },
    SET_FILTERS(state, data) {
      state.filters.categories = data.categories
      state.filters.date.from = data.fromDate
      state.filters.date.to = data.toDate
      state.filters.wallet = data.wallet
    },
    SET_REQUEST_FILTERS(state, data) {
      state.requestFilters.categories = data.categories
      state.requestFilters.date.from = data.fromDate
      state.requestFilters.date.to = data.toDate
      state.requestFilters.wallet = data.wallet
    },
    RESET_NEXT_PAGE(state) {
      state.nextPage = 1
    },
    CLEAR_FILTERS(state) {
      state.filters.categories = {}
      state.requestFilters.categories = {}
      state.filters.date.from = null
      state.filters.date.to = null
      state.requestFilters.date.from = null
      state.requestFilters.date.to = null
      state.requestFilters.wallet = null
    },
    SET_TRANSACTION(state, transaction) {
      state.transaction = transaction
    },
    SET_LAST_TOPUP(state, transaction) {
      const topup = transaction.transactions && transaction.transactions.length ? transaction.transactions[0] : transaction;
      state.lastTopup = topup;
    },
    SET_VOUCHERS(state, vouchers) {
      state.pendingVouchers = vouchers
    }
  },
  actions: {
    fetchTransactions({ commit, state }, data) {
      let params = {
        ...(data.categories
          ? { categories: data.categories }
          : state.requestFilters.categories.length
          ? { categories: Object.values(state.requestFilters.categories) }
          : undefined),
        ...(state.requestFilters.date.from
          ? { from: state.requestFilters.date.from }
          : undefined),
        ...(state.requestFilters.date.to
          ? { to: state.requestFilters.date.to }
          : undefined),
        ...(state.requestFilters.wallet || data.lastTopup
          ? {
              wallet: data.lastTopup ? data.wallet : state.requestFilters.wallet
            }
          : undefined),
        ...(data.group ? { group: true } : { group: false }),
        total: data.total,
        project_id: data.project_id,
        page: data.page,
        order: data.order
      }
        return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/transactions', {
          params: params
        })
        .then((res) => {
          if(data?.lastTopup) {
            commit('SET_LAST_TOPUP', res.data)
          }
          if(data.group === false) commit('SET_SAMPLE_TRANSACTIONS', res.data)
          else commit('SET_TRANSACTIONS', res.data)
          return res
      })
    },
    // eslint-disable-next-line no-unused-vars
    fetchVpTransaction({ commit, state }, data) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/transactions/vp/' + data)
        .then((res) => {
          return res.data
        })
    },
    addFilters({ commit }, data) {
      commit('SET_FILTERS', data.filters)
      commit('SET_REQUEST_FILTERS', data.requestFilters)
    },
    resetNextPage({ commit }) {
      commit('RESET_NEXT_PAGE')
    },
    clearFilters({ commit }) {
      commit('CLEAR_FILTERS')
    },
    setTransaction({ commit }, transaction) {
      commit('SET_TRANSACTION', transaction)
    },
    fetchPendingVouchers({ commit }, profileId) {
      const params = {
        profile: profileId
      }
      return axios
        .get(
          process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/transactions/vouchers',
          { params }
        )
        .then((data) => {
          commit('SET_VOUCHERS', data.data)
          return data
        })
    }
  },
  getters: {
    getActiveFilters: (state) => () => {
      let active = 0
      for (let category in state.filters.categories) {
        if (state.filters.categories[category]) active = active + 1
      }
      return active
    }
  }
}
