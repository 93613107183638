<template>
  <div class="steps">
    <div>
      <div class="steps__title--mobile ml-5 md:ml-0">
        {{ $t('topup.confirmation.' + method + '.title', 1) }}
      </div>
      <div class="steps__subtitle">
        {{ $tm('topup.confirmation.' + method + '.subtitle') }}
        <span class="font-bold">$ {{ dollarFormatting(amount) }}</span>
        {{ $tm('topup.confirmation.' + method + '.subtitle_2') }}
      </div>
    </div>
    <div class="indented-custom-bullet mb-14 mt-8">
      <ul>
        <li
          v-for="el in $tm('topup.confirmation.etransfer.list')"
          v-bind:key="el"
          class="pl-4 mb-3 text-grey-3 text-sm lg:text-base"
        >
          {{ el }}
        </li>
      </ul>
    </div>
    <div class="absolute lg:bottom-0 bottom-18 w-4/5 md:w-3/4">
      <GpButton
        data-test="continueButton"
        mobileWidth="w-full"
        type="submit"
        class="absolute right-0 bottom-12 lg:bottom-0"
        @click="finish"
        :disabled="loading"
      >
        {{ $t('wording.continue') }}
      </GpButton>
    </div>
  </div>
</template>

<script>
import GpButton from '@/components/general/GpButton.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'

export default {
  components: {
    GpButton,
  },
  props: {
    wallet: Object,
    isActive: Boolean
  },
  emits: ['finish'],
  data() {
    return {
      openPopup: false
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const notAgain = ref(false)
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const show = ref(null)
    const i18n = useI18n({ useScope: 'global' })
    const { dollarFormatting } = useGlobalHelpers()

    const tableData = ref({
      top: [],
      bottom: []
    })

    /** COMPUTED **/
    const loading = computed(() => {
      return store.state.general.loading
    })

    const isPromoter = computed(() => {
      return profile.value.promoter_id ? true : false
    })

    const details = computed(() => {
      return store.state.topup.details
    })

    const method = computed(() => {
      return store.state.topup.method
    })
    const amount = computed(() => {
      return store.state.topup.amount
    })

    /** FUNCTIONS **/

    function sendDetails(method) {
      store
        .dispatch('sendDetails', {
          method: method,
          id: props.wallet.id,
          type: 'wallet'
        })
        .then(() => {
          show.value = method
          setTimeout(function () {
            show.value = null
          }, 500)
        })
        .catch((e) => {
          store.dispatch('setError', e)
        })
    }


    watch(
      () => props.isActive,
      async () => {
        if (!props.isActive) {
          tableData.value = {
            top: [],
            bottom: []
          }
        }
      }
    )

    function finish() {
      localStorage.setItem('hide_topup_confirmation', notAgain.value)
      emit('finish')
    }

    return {
      method,
      amount,
      finish,
      show,
      notAgain,
      loading,
      dollarFormatting,
      sendDetails,
      details,
      profile,
      tableData,
      isPromoter
    }
  }
}
</script>
<style scoped>
  .bg-gradient-green {
    background-image: linear-gradient(
      114deg,
      rgba(0, 231, 99, 0.2) -18%,
      rgba(238, 243, 235, 0.2) 90%
    );
  }

  .bg-white-opacity {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .v-enter-active,
  .v-leave-active {
    transition: 0.2s linear;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0.5;
  }
</style>
