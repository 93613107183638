import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";
import {handle_age, handle_onboarding} from "@/assets/ts/onboarding/callbacks/handle_onboarding";

const questions:PageForm =
  {
    title: i18n.global.t('ca_legislation.onboarding.sidebar_title'),
    image: 'img/lamp-sidebar.png',
    form: [
      {
        title: i18n.global.t("ca_legislation.onboarding.questions.title_questions2"),
        fields: [
          {
            key: "countryLocation",
            title: i18n.global.t("ca_legislation.onboarding.questions.country_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions.country_text"),
            type: Types.COUNTRIES,
            rules: 'required'
          },
          {
            key: "region",
            title: i18n.global.t("ca_legislation.onboarding.questions.region_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions.region_text"),
            options:i18n.global.tm("ca_legislation.onboarding.questions.region_options"),
            type: Types.MULTISELECT,
            rules: 'required'
          },
          {
            key: "date_of_birth",
            title: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_18_text"),
            type: Types.DATE,
            limit: {min: 18, max: 100},
            condition: {
              key: 'region',
              value: 'MB'
            },
            rules: 'required'
          },
          {
            key: "date_of_birth",
            title: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_18_text"),
            type: Types.DATE,
            limit: {min: 18, max: 100},
            condition: {
              key: 'region',
              value: 'AB'
            },
            rules: 'required'
          },
          {
            key: "date_of_birth",
            title: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_18_text"),
            type: Types.DATE,
            limit: {min: 18, max: 100},
            condition: {
              key: 'region',
              value: 'ON'
            },
            rules: 'required'
          },
          {
            key: "date_of_birth",
            title: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_19_text"),
            type: Types.DATE,
            limit: {min: 19, max: 100},
            condition: {
              key: 'region',
              value: 'BC'
            },
            rules: 'required'
          },
          {
            key: "date_of_birth",
            title: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_19_text"),
            type: Types.DATE,
            limit: {min: 19, max: 100},
            condition: {
              key: 'region',
              value: 'NS'
            },
            rules: 'required'
          },
          {
            key: "date_of_birth",
            title: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_title"),
            subtitle: i18n.global.t("ca_legislation.onboarding.questions.date_of_birth_19_text"),
            type: Types.DATE,
            limit: {min: 19, max: 100},
            condition: {
              key: 'region',
              value: ''
            },
            rules: 'required'
          },
            {
              key: "address",
              title: i18n.global.t("ca_legislation.onboarding.questions.address_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.address_text"),
              type: Types.STRING,
              rules: 'required|regex:^[a-zA-Z0-9\\s,.-]+$'
            },
            {
              key: "post_code",
              title: i18n.global.t("ca_legislation.onboarding.questions.post_code_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.post_code_text"),
              type: Types.STRING,
              rules: 'required|exactLength:6'
            },
            {
              key: "city",
              title: i18n.global.t("ca_legislation.onboarding.questions.city_title"),
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.city_text"),
              type: Types.STRING,
              rules: 'required'
            }
        ],
        next: true,
        otherCallback: handle_age,
        nextCallback: handle_onboarding,
        back: true
      }
    ]
  }

export default questions
