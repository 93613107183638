import axios from 'axios'
import { useStore } from 'vuex'

export default {
  state: {
    allContracts: [],
    homepageContracts: [],
    activeContracts: [],
    settledContracts: [],
    contract: null,
    filters: null,
    offerPreview: null,
    search: null,
    active_contract_next_page: 1,
    requesting: false,
    settled_contract_next_page: 1,
    active_contract_pages: [],
    settled_contract_pages: [],
    active_contract_has_next_page: true,
    settled_contract_has_next_page: true,
    totalActiveContracts: 0,
    totalSettledContracts: 0,
    currentTab: 1,
  },
  mutations: {
    SET_ALL_CONTRACTS(state, contracts) {
      state.allContracts = state.allContracts.concat(contracts)
    },
    SET_HOMEPAGE_CONTRACTS(state, contracts) {
      state.homepageContracts = contracts
    },
    SET_ACTIVE_CONTRACTS(state, contracts) {
      state.activeContracts = state.activeContracts.concat(contracts)
    },
    SET_SETTLED_CONTRACTS(state, contracts) {
      state.settledContracts = state.settledContracts.concat(contracts)
    },
    SET_REQUESTING(state, requesting) {
      state.requesting = requesting
    },
    SET_ACTIVE_CONTRACTS_PAGE(state, page) {
      state.active_contract_pages.push(page)
    },
    SET_SETTLED_CONTRACTS_PAGE(state, page) {
      state.settled_contract_pages.push(page)
    },
    RESET_ACTIVE_CONTRACTS(state) {
      state.activeContracts = []
      state.active_contract_pages = []
      state.active_contract_has_next_page = true
      state.active_contract_next_page = 1
    },
    RESET_SETTLED_CONTRACTS(state) {
      state.settledContracts = []
      state.settled_contract_pages = []
      state.settled_contract_has_next_page = true
      state.settled_contract_next_page = 1
    },
    SET_CONTRACT(state, contract) {
      state.contract = contract
    },
    SET_SEARCH(state, search) {
      state.search = search
    },
    SET_CONTRACT_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_OFFER_PREVIEW(state, offer) {
      state.offerPreview = offer
    },
    SET_ACTIVE_CONTRACT_NEXT_PAGE(state, next_page) {
      state.active_contract_next_page = next_page
    },
    SET_SETTLED_CONTRACT_NEXT_PAGE(state, next_page) {
      state.settled_contract_next_page = next_page
    },
    SET_ACTIVE_CONTRACT_HAS_NEXT_PAGE(state, has_next_page) {
      state.active_contract_has_next_page = has_next_page
    },
    SET_SETTLED_CONTRACT_HAS_NEXT_PAGE(state, has_next_page) {
      state.settled_contract_has_next_page = has_next_page
    },
    SET_TOTAL_ACTIVE_CONTRACTS(state, total) {
      state.totalActiveContracts = total
    },
    SET_TOTAL_SETTLED_CONTRACTS(state, total) {
      state.totalSettledContracts = total
    },
    SET_CURRENT_TAB(state, tab) {
      state.currentTab = tab
    }
  },
  actions: {
    fetchHomepageContracts({commit}){
      return new Promise((resolve, reject) => {
        let payload = {}

        payload['sort'] = 'next_payment'
        payload.pagination = 1
  
        axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/contracts', {
          params: payload
        }).then((contracts) => {
          commit('SET_HOMEPAGE_CONTRACTS', contracts.data.data)
          resolve()
        })
      })

    },
    fetchContracts({ commit, state, dispatch, getters }, data) {
      if(!state.requesting){
        commit('SET_REQUESTING', true)
        let payload = {}

        if(data.reset){
          if(data.fetchState === 'active')
            commit('RESET_ACTIVE_CONTRACTS')
          else
            commit('RESET_SETTLED_CONTRACTS')
        }

        if(data.sort)
          payload['sort'] = data.sort

        if(data.fetchState !== false) {
          payload['filter[state_api]'] = data.fetchState
          payload.page = data.fetchState === 'settled' ?  state.settled_contract_next_page : state.active_contract_next_page
          payload.pagination = 1
        }

        if(state.filters){
          payload = {
            ...payload,
            ...getters.getContractsFilterQuery
          }
        }

        if (
            (data.fetchState === 'active' && state.active_contract_has_next_page && !state.active_contract_pages.includes(state.active_contract_next_page)) ||
            (data.fetchState === 'settled' && state.settled_contract_has_next_page && !state.settled_contract_pages.includes(state.settled_contract_next_page)) ||
            data.fetchState === false) {
          axios
              .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/contracts', {
                params: payload
              })
              .then((contracts) => {

                  commit('SET_REQUESTING', false)
                if(data.fetchState) {
                  if(data.fetchState === 'settled') {
                    commit('SET_SETTLED_CONTRACTS', contracts.data.data)
                    commit('SET_SETTLED_CONTRACT_HAS_NEXT_PAGE', contracts.data.next_page_url)
                    commit('SET_SETTLED_CONTRACTS_PAGE', state.settled_contract_next_page)
                    commit('SET_SETTLED_CONTRACT_NEXT_PAGE', state.settled_contract_next_page + 1)
                    commit('SET_TOTAL_SETTLED_CONTRACTS', contracts.data.total)
                  }
                  else if (data.fetchState === 'active') {
                    commit('SET_ACTIVE_CONTRACTS', contracts.data.data)
                    commit('SET_ACTIVE_CONTRACT_HAS_NEXT_PAGE', contracts.data.next_page_url)
                    commit('SET_ACTIVE_CONTRACTS_PAGE', state.active_contract_next_page)
                    commit('SET_ACTIVE_CONTRACT_NEXT_PAGE', state.active_contract_next_page + 1)
                    commit('SET_TOTAL_ACTIVE_CONTRACTS', contracts.data.total)
                  }
                  commit('SET_ALL_CONTRACTS', contracts.data.data)
                }
              })
              .catch((e) => {
                dispatch('setError', e)
                commit('SET_REQUESTING', false)
              })
        }
      }

    },
    fetchContract({ commit }, id) {
      const store = useStore()
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/contracts/' + id)
        .then((data) => {
          commit('SET_CONTRACT', data.data)
        })
        .catch((e) => {
          store.dispatch('setError', e)
        })
    },
    storeContract({ commit }, contract) {
      commit('SET_CONTRACT', contract)
    },
    setContractFilters({ commit }, filters) {
      commit('SET_CONTRACT_FILTERS', filters)
    },
    setSearch({ commit }, search) {
      commit('SET_SEARCH', search)
    },
    setCurrentTab({ commit }, tab) {
      commit('SET_CURRENT_TAB', tab)
    },
    previewOffer({ commit }, contractId) {
      const store = useStore()
      return axios
        .get(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/contracts/' +
            contractId +
            '/offer'
        )
        .then((data) => {
          commit('SET_OFFER_PREVIEW', data.data)
        })
        .catch((e) => {
          store.dispatch('setError', e)
        })
    }
  },
  getters: {
    getContracts:
      (state) =>
      (sortBy = null, contractState = null) => {
        let filtered = null
        if(contractState === 'settled') filtered = state.settledContracts 
        else if(contractState === 'active')  filtered = state.activeContracts
        
        if (filtered) {
          // // Settled filter
          // if (settled) {
          //   filtered = state.contracts.filter((c) => {
          //     return c.state === 'settled'
          //   })
          // } else {
          //   filtered = state.contracts.filter((c) => {
          //     return c.state !== 'settled'
          //   })
          // }
          /*if (state.filters) {
            // Status filter
            if (state.filters.paymentStatus) {
              if (state.filters.paymentStatus === 'arrears') {
                filtered = filtered.filter((c) => {
                  return c.payment_in_arrears
                })
              } else if (state.filters.paymentStatus === 'on_time') {
                filtered = filtered.filter((c) => {
                  return !c.payment_in_arrears
                })
              }
            }
            // Risk filter
            if (
              state.filters.risk &&
              Object.values(state.filters.risk).includes(true)
            ) {
              filtered = filtered.filter((c) => {
                return state.filters.risk[c.project_risk]
              })
            }
            // Category filter
            if (
              state.filters.categories &&
              Object.values(state.filters.categories).includes(true)
            ) {
              filtered = filtered.filter((c) => {
                return state.filters.categories[c.project_category]
              })
            }
            // Wallet filter
            if (
              state.filters.wallets &&
              Object.values(state.filters.wallets).includes(true)
            ) {
              filtered = filtered.filter((c) => {
                return state.filters.wallets[c.vp_wallet]
              })
            }
          }*/
          if (sortBy)
            return filtered.sort((a, b) => {
              switch (sortBy) {
                case '_next_payment':
                  if (
                    new Date(a.upcoming_payments.next_payment.schedule_date) <=
                    new Date(b.upcoming_payments.next_payment.schedule_date)
                  )
                    return -1
                  else return 1
                case 'outstanding_low':
                  if (
                    a.upcoming_payments.next_payment.capital_outstanding <=
                    b.upcoming_payments.next_payment.capital_outstanding
                  )
                    return -1
                  else return 1
                case 'outstanding_high':
                  if (
                    a.upcoming_payments.next_payment.capital_outstanding >=
                    b.upcoming_payments.next_payment.capital_outstanding
                  )
                    return -1
                  else return 1
                case 'end_recent_first':
                  if (new Date(a.end_date) <= new Date(b.end_date)) return -1
                  else return 1
                case 'end_recent_last':
                  if (new Date(a.end_date) >= new Date(b.end_date)) return -1
                  else return 1
                case 'first_settled':
                  if (new Date(a.settled_at) <= new Date(b.settled_at)) return -1
                  else return 1
                case 'last_settled':
                  if (new Date(a.settled_at) >= new Date(b.settled_at)) return -1
                  else return 1
                case 'paid_high':
                  if (a.contract_gross_value.total_paid >=
                    b.contract_gross_value.total_paid) return -1
                  else return 1
                case 'paid_low':
                  if (a.contract_gross_value.total_paid <=
                    b.contract_gross_value.total_paid) return -1
                  else return 1
                default:
                  return filtered
              }
            })
          else return filtered
        } else return null
      },
    getPaymentsPreview: (state) => () => {
      if (state.contract) {
        if (state.contract.upcoming_payments.upcoming_payments_count >= 4) {
          let all = state.contract.all_payments.filter((p) => {
            return (
              parseInt(p.payment_n) >=
              parseInt(state.contract.upcoming_payments.next_payment.payment_n)
            )
          })
          return all.slice(0, 4)
        } else
          return state.contract.all_payments
            ? state.contract.all_payments.slice(-4)
            : null
      } else return null
    },
    getContractsFilterQuery: (state) => {

        let queryParams = {};

        for (let key in state.filters) {
          let value = state.filters[key];

          if (value === null) {
            continue;
          }

          if (typeof value === 'object') {
            let arr = []
            for (let subKey in value) {
              if (value[subKey]) {
                arr.push(subKey)
              }
            }
            queryParams[`filter[${key}]`] = arr
          } else {
            queryParams[`filter[${key}]`] = value
          }
        }
        return queryParams;
    }
  }
}
