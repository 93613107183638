<template>
  <Form
    @submit="next"
    class="steps form flex-grow flex flex-col justify-between"
    ref="form"
    v-slot="{ errors, meta }"
    style="padding-bottom: max(13rem,0%);"
  >
    <div>
      <div v-if="isPromoter">
        <div v-if="topUpAmount || amortization">
          <div class="steps__title--mobile ml-5 md:ml-0">
            {{ $tm('promoter.topup.topup_amount.title') }}
          </div>
          <div v-if="isPayNow" class="steps__subtitle bold">
            {{ $t('promoter.topup.topup_amount.subtitle_in_arrears') }}
          </div>
          <div v-else>
            <div v-if="DdFailed && loan" class="steps__subtitle bold">
              {{ $t('promoter.topup.topup_amount.subtitle_dd_failed') }}
              <span class="font-bold">$ {{ dollarFormatting(amortization) }}</span>
              {{ $tm('promoter.topup.topup_amount.subtitle_dd_failed_1') }}
              <span class="font-bold"
                >${{
                  dollarFormatting(loan.loan_status.extra_payments_dd)
                }}.</span
              >
            </div>
            <div v-else>
              <div class="steps__subtitle bold">
                {{ $t('promoter.topup.topup_amount.subtitle') }}
                <span class="font-bold"
                  >${{ dollarFormatting(amortization) }}</span
                >
                {{ $tm('promoter.topup.topup_amount.subtitle_2') }}
              </div>
            </div>
          </div>
        </div>
        <GeneralInput
          v-model:data="topUpAmount"
          type="dollar"
          name="amount"
          :error="errors.amount"
          :label="$t('promoter.topup.topup_amount.placeholder')"
          rules="required|minValue:500"
          prefix="$"
          @click="() => (showAmounts = true)"
        />
        <div v-if="showAmounts" class="flex mb-4 flex-wrap">
          <div
            v-for="el in promoterAmounts"
            v-bind:key="el"
            class="
              text-13 text-orange-1
              font-bold
              px-4
              py-2
              bg-white
              rounded-xl
              mr-3
              cursor-pointer
              mt-3
              lg:mt-0
            "
            @click="topUpAmount = dollarFormatting(el)"
          >
            $ {{ dollarFormatting(el) }} 
          </div>
        </div>
        <div
          class="
            mt-6
            py-4
            px-4
            text-grey-3 text-sm
            font-medium
            rounded-3xl
            bg-grey-7
          "
        >
          {{ $tm('promoter.topup.topup_amount.warning') }}
        </div>
      </div>

      <div v-else>
        <div class="steps__title--mobile ml-5 md:ml-0">
          {{ $tm('topup.amount.title') }}
        </div>
        <div class="steps__subtitle">
          {{
            $t('topup.amount.subtitle', {
              name: profile.name,
              wallet_name: walletName
            })
          }}
        </div>
        <GeneralInput
          v-model:data="amount"
          type="dollar"
          name="amount"
          :error="errors.amount"
          :label="$t('topup.amount.placeholder')"
          :rules="'required|minValue:'+minTopup+'|maxValue:' + maxTopup"
          prefix="$"
        />
        <div class="flex mb-4 flex-wrap">
          <div
            v-for="el in method == 'etransfer' ? amounts : wire_amounts"
            v-bind:key="el"
            class="
              text-13 text-orange-1
              font-bold
              px-4
              py-2
              bg-white
              rounded-xl
              mr-3
              cursor-pointer
              mt-3
              lg:mt-0
            "
            @click="amount = dollarFormatting(el)"
          >
            $ {{ dollarFormatting(el) }} 
          </div>
        </div>
      </div>
    </div>

    <div class="absolute button-bottom lg:bottom-8 w-4/5 md:w-3/4">
      <div class="block lg:hidden mb-6 flex justify-between">
        {{ $t('home.balance') }}
        <div class="font-semibold">
          {{ balance }}
        </div>
      </div>
      <GpButton
        mobileWidth="w-full"
        type="submit"
        :disabled="!meta.valid || loading || !profile.can_check || !profile.lend_ack"
        class="md:absolute right-0 bottom-0"
      >
        {{ $t('wording.continue') }}
      </GpButton>
    </div>
  </Form>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { Form } from 'vee-validate'
import GpButton from '@/components/general/GpButton.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import GeneralInput from '@/components/general/Input.vue'

export default {
  components: {
    Form,
    GpButton,
    GeneralInput
  },
  props: {
    balance: String,
    walletName: String,
    method: String,
    isPromoter: {
      type: Boolean,
      default: false
    },
    amortizationAmount: Number,
    isPayNow: {
      type: Boolean,
      default: false
    },
    presetAmount: String,
    isActive: Boolean
  },
  data() {
    return {
      openPopup: false
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const { dollarFormatting, dollarToNumberFormatting } = useGlobalHelpers()
    const i18n = useI18n({ useScope: 'global' })
    const form = ref(null)
    const settingsConfig = require("@/config/settings.config.js");
    const amount = ref(
      props.presetAmount && props.presetAmount !== ''
        ? props.presetAmount
        : null
    )
    const amounts = ref([2000, 10000, 50000, 100000])
    const wire_amounts = ref([2000, 20000, 100000, 1000000])
    const promoterAmounts = ref([10000, 50000, 100000, 200000])
    const amortization = ref(null)
    const topUpAmount = ref(null)
    const showAmounts = ref(false)

    const minTopup = computed(() => {
      return (settings.value && settings.value.min_topup) ? settings.value.min_topup : 1000
    })

    // final limit
    const maxTopup = computed(() => {
      
      // can't topup if
      if(!profile.value.lend_ack || 
        isBorrower.value || 
        !profile.value.activated || 
        !profile.value.can_check || 
        ['unsuitable', 'unsuitable_pending'].includes(profile.value.sub_type)
      ) return 0
      
      if(props.method == 'etransfer') return 300000

      return (settings.value && settings.value.vp_indv_limit) ? settings.value.vp_indv_limit : 1000000
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const confirmation = computed(() => {
      return store.state.topup.amountNotEnoughConfirmation
    })

    const loading = computed(() => {
      return store.state.general.loading
    })

    const loan = computed(() => {
      return store.state.promoters.loan
    })

    const DdFailed = computed(() => {
      return (
        store.state.promoters.loan.allow_dd &&
        store.state.promoters.loan.loan_status.extra_payments_dd > 0
      )
    })

    const settings = computed(() => {
      return store.state.general.settings
    })
    const isBorrower = computed(() => {
      return profile.value && profile.value.sub_type === 'borrower'
    })

    watch(
      () => props.isActive,
      async () => {
        if (props.isActive && (!profile.value.can_check || !profile.value.lend_ack)) {
        const popup = {
          title: i18n.t('promoter.topup.popup_invalid_user.title'),
          align: 'center',
          side: false,
          iconBg: 'bg-grey-8',
          icon: '/img/popup/exclamation_orange.svg',
          content: i18n.t('promoter.topup.popup_invalid_user.content'),
          primaryButton: i18n.t('promoter.topup.popup_invalid_user.button')
        }
        await store.dispatch('setPopup', popup)
      }
      }
    )

    onMounted(async () => {
      await store.dispatch('setRequestSettings', settingsConfig.toString())
      await store.dispatch('fetchSuitability')

      if (props.isPromoter) {
        amortization.value = props.amortizationAmount
        topUpAmount.value = DdFailed.value
          ? dollarFormatting(
              amortization.value + loan.value.loan_status.extra_payments_dd
            )
          : dollarFormatting(props.amortizationAmount)
        if (props.isPayNow)
          topUpAmount.value = dollarFormatting(store.state.topup.amount)
      }
    })

    async function next() {
      if (props.isPromoter) {
        if (props.amortizationAmount) amount.value = topUpAmount.value

        //warning when amount is not enough to pay for the next amortization
        if (
          Math.round(dollarToNumberFormatting(topUpAmount.value)) <
            Math.round(amortization.value) &&
          !props.isPayNow
        ) {
          openPopup()
        } else if (
          props.isPayNow &&
          Math.round(dollarToNumberFormatting(topUpAmount.value)) <
            Math.round(store.state.topup.amount)
        ) {
          openPopup()
        } else {
          await store.dispatch(
            'setAmount',
            dollarToNumberFormatting(amount.value)
          )
          emit('next')
        }
      } else {
        await store.dispatch('setAmount', dollarToNumberFormatting(amount.value))
        emit('next')
      }
    }

    const openPopup = () => {
      const popup = {
        align: 'center',
        side: false,
        icon: '/img/popup/exclamation_orange.svg',
        component: 'ConfirmAmountTopUp',
        props: { loan: store.state.promoters.loan }
      }
      store.dispatch('setPopup', popup)
    }

    watch(
      () => confirmation.value,
      async () => {
        if (confirmation.value) {
          await store.dispatch(
            'setAmount',
            dollarToNumberFormatting(amount.value)
          )
          emit('next')
        }
      }
    )

    return {
      form,
      next,
      loading,
      amount,
      settings,
      dollarFormatting,
      amounts,
      wire_amounts,
      promoterAmounts,
      maxTopup,
      minTopup,
      profile,
      amortization,
      topUpAmount,
      DdFailed,
      loan,
      showAmounts
    }
  }
}
</script>
