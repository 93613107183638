<template>
  <div class="flex flex-col items-center">
      <img
      src="/img/popup/flag-ca.svg"
      alt="CA-flag"
      class="w-24 rounded-lg sm:w-40 mb-8"
      />
      <div class="text-center">
        <!-- title -->
        <p class="font-bold mb-4 text-lg text-grey-2">
          {{ $t('signup.wrong_timezone_popup.title') }}
        </p>
        <!-- description -->
        <p class="text-sm text-grey-2 text-opacity-60 text-center mb-16">
          {{ $t('signup.wrong_timezone_popup.description') }}
        </p>
      </div>
      <GpButton @click="redirect" class="w-full max-w-xs mb-2">
        {{ $t('signup.wrong_timezone_popup.btn') }}
      </GpButton>
      <a class="orange-link w-max mx-auto" @click="close">
        {{ $t('signup.wrong_timezone_popup.close') }}
      </a>
  </div>
</template>

  <script>
  import GpButton from '@/components/general/GpButton.vue'
  import { useStore } from 'vuex'
  
  
  export default {
      components: {
      GpButton
    },
    setup(){
      const store = useStore()
  
      function redirect () {
          window.location.href = process.env.VUE_APP_FE_BASE_URL_PT + '/signup'
      }

      function close () {
          store.dispatch('setShowPopup', false)
      }
      
      return {
        redirect,
        close
      }
    }
  }
  </script>