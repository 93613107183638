<template>
  <div class="page-no-nav flex">
    <div class="lg:pt-20 container flex-grow relative min-p-button">
      <div @click="checkChanges">
        <Back :to="null" :title="$t('settings.personal_details.title')" />
      </div>
      <div
        v-if="profile && kycsConditions"
        class="flex flex-col justify-between"
      >
        <div class="flex text-left text-grey-3 mt-6 lg:mt-2 mb-6 text-sm">
          {{
            $t(
              'settings.personal_details.status.' +
                kycsConditions.localesPath +
                '.text'
            )
          }}
          <router-link
            v-if="
              kycsConditions.localesPath !== 'validation_asked' &&
              kycsConditions.localesPath !== 'validated'
            "
            to="/dashboard/kyc"
            class="flex orange-link text-xs cursor-pointer ml-1"
          >
            {{
              $t(
                'settings.personal_details.status.' +
                  kycsConditions.localesPath +
                  '.btn'
              )
            }}
          </router-link>
        </div>
        <Form class="form" ref="form" v-slot="{ errors, meta }">
          <GeneralInput
            v-model:data="profile.name"
            type="text"
            name="names"
            :label="$t('settings.personal_details.names')"
            :locked="kycRegular"
            :noNumbersOrSymbols="true"
            rules="required|alphabet|maxLength:60"
            :error="errors.names"
          />
          <GeneralInput
            v-model:data="profile.surname"
            type="text"
            name="surnames"
            :label="$t('settings.personal_details.surnames')"
            :locked="kycRegular"
            :noNumbersOrSymbols="true"
            rules="required|alphabet|maxLength:60"
            :error="errors.surnames"
          />
          <GeneralInput
            ref="dateInput"
            v-model:data="profile.date_of_birth"
            type="date"
            :fromDate="from_more_1_day"
            :toDate="to"
            name="date_of_birth"
            :label="$t('settings.personal_details.date_of_birth')"
            :locked="kycRegular"
            :required="true"
          />
          <GeneralInput
            v-model:data="profile.email"
            type="email"
            name="email"
            :label="$t('settings.personal_details.email')"
            :locked="true"
          />
          <GeneralInput
            v-model:data="profile.phone"
            type="number"
            name="phone"
            :label="$t('settings.personal_details.phone_number')"
            rules="required|exactLength:10"
            :locked="kycRegular"
            :error="errors.phone"
          />          
          <GeneralInput
            v-model:data="maskedSin"
            type="string"
            name="sin"
            :label="$t('settings.personal_details.sin')"
            rules="required|exactLength:9"
            :locked="true"
            :error="errors.sin"
          />
          <GeneralInput
            v-model:data="profile.gender"
            type="multiselect"
            name="gender_multiselect"
            :label="$t('settings.personal_details.gender.input_label')"
            :options="genderOptions"
            :multiselectLabel="'name'"
            :multiselectValueProp="'value'"
            :multiselectTrackBy="'name'"
            :searchable="false"
            :required="true"
          />
          <div v-if="kycRegular"
            class="
              w-full
              text-left text-grey-3
              lg:text-grey-4
              mr-3
              md:mr-0
              mb-10
              text-13
            "
          >
            {{ $t('settings.personal_details.update_info') }}
            <div
              class="
                inline
                text-left
                mt-9
                lg:mt-4
                mb-6
                text-orange-1
                text-sm
                font-bold
              "
              >
              <a :href="`mailto:${$t('settings.personal_details.update_info_link')}`">
                {{ $t('settings.personal_details.update_info_link') }}
              </a> 
            </div>
          </div>
          <div>
            <div class="w-full relative">
              <GpButton
                :disabled="!(isDirty || meta.dirty) || !meta.valid || loading"
                @click="updateDetails"
                class="ml-auto"
              >
                {{ $t('settings.personal_details.success_btn') }}
              </GpButton>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import useProfile from '@/mixins/useProfile.js'
import { useI18n } from 'vue-i18n'
import { Form } from 'vee-validate'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useRules from '@/assets/js/rules'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import GeneralInput from '@/components/general/Input.vue'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import axios from 'axios'

export default {
  components: {
    GeneralInput,
    Back,
    Form,
    GpButton
  },
  setup() {
    const i18n = useI18n({ useScope: 'global' })
    let genderOptions = [
      {
        name: i18n.t('settings.personal_details.gender.female'),
        value: 'female'
      },
      {
        name: i18n.t('settings.personal_details.gender.male'),
        value: 'male'
      },
      {
        name: i18n.t('settings.personal_details.gender.non_binary'),
        value: 'nonbinary'
      },
      {
        name: i18n.t('settings.personal_details.gender.no_answer'),
        value: 'none'
      }
    ]
    let incomeSubType = [
      {
        name: i18n.t(
          'settings.personal_details.annual_gross_income.accredited'
        ),
        value: 'Accredited'
      },{
        name: i18n.t(
          'settings.personal_details.annual_gross_income.retail'
        ),
        value: 'Retail or Suitable'
      },{
        name: i18n.t(
          'settings.personal_details.annual_gross_income.unsuitable'
        ),
        value: 'Unsuitable'
      }
    ]
    const store = useStore()
    const router = useRouter()
    const { updateProfile } = useProfile()
    const { isRequired } = useRules()
    const { prettierDate } = useGlobalHelpers()
    const now = new Date(Date.now())
    const nowYear = now.getFullYear()
    const from = new Date(now.setFullYear(nowYear - 120))
    const from_more_1_day = new Date(from)
    from_more_1_day.setDate(from_more_1_day.getDate() + 1)
    const to = new Date(now.setFullYear(nowYear - 18))
    const profile = ref(null)
    const form = ref(null)
    const initialProfile = ref(null)
    const maskedSin = ref(null)

    const kycRegular = computed(() => {
      return profile.value.kyc_level === 'regular'
    })

    const kycsConditions = computed(() => {
      return store.state.kycs.conditions
    })

    const loading = computed(() => {
      return store.state.general.loading
    })

    onMounted(async () => {
      profile.value = store.getters.getProfile()
      if (!store.state.kycs.kycs) {
        store.dispatch('setLoading', true)
        await store.dispatch('getKycs')
      }
      if (!kycsConditions.value) {
        store.dispatch('setLoading', true)
        const conditions = await store.getters.getKycsConditions()
        await store.dispatch('setKycsConditions', conditions)
      }
      if(!maskedSin.value) {
        store.dispatch('setLoading', true)
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/profiles/mask-sin')
        .then((data) => {
          maskedSin.value = data.data
        }).catch((e) => {
          store.dispatch('setLoading', false);
          store.dispatch('setError', e)
        })
      }

      store.dispatch('setLoading', false)
      profile.value.country_code = profile.value.country_code
        ? profile.value.country_code
        : '1'
      initialProfile.value = JSON.parse(JSON.stringify(profile.value))
      loading.value = false
    })

    const isDirty = computed(() => {
      return (
        JSON.stringify(profile.value) !== JSON.stringify(initialProfile.value)
      )
    })

    const updateDetails = () => {
      store.dispatch('setLoading', true)
      profile.value.date_of_birth = prettierDate(profile.value.date_of_birth)

      const allowedKeys = [
        'name',
        'surname',
        'date_of_birth',
        'citizenship',
        'email',
        'phone',
        'country_code',
        'gender',
        'sub_type'
      ]
      const profileFiltered = Object.keys(profile.value)
        .filter((key) => {
          return allowedKeys.includes(key) && profile.value[key]
        })
        .reduce((obj, key) => {
          obj[key] = profile.value[key]
          return obj
        }, {})

      updateProfile(profileFiltered)
        .then(() => {
          form.value.resetForm()
          store.dispatch('setLoading', false)
          const popup = {
            title: i18n.t('settings.personal_details.success_popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            primaryButton: i18n.t('settings.personal_details.success_popup.btn')
          }
          store.dispatch('setPopup', popup)
          router.push({ name: 'settings' })
        })
    }

    const checkChanges = () => {
      if (isDirty.value === true) {
        const popup = {
          title: i18n.t('settings.discard_changes.title'),
          align: 'center',
          side: false,
          content: i18n.t('settings.discard_changes.content'),
          iconBg: 'bg-grey-7',
          icon: '/img/popup/exclamation_orange.svg',
          primaryButton: i18n.t('wording.no_go_back'),
          secondaryButton: i18n.t('wording.discard_changes'),
          secondaryLink: '/settings'
        }
        store.dispatch('setPopup', popup)
      } else {
        router.push({ name: 'settings' })
      }
    }

    return {
      profile,
      loading,
      i18n,
      kycRegular,
      genderOptions,
      incomeSubType,
      from,
      from_more_1_day,
      to,
      updateDetails,
      isRequired,
      form,
      isDirty,
      kycsConditions,
      checkChanges,
      maskedSin,
      now,
      nowYear
    }
  }
}
</script>

<style lang="sass" scoped></style>
