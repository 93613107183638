<template>
  <div class="page" style="padding-top: 0">
    <div v-if="profile" class="lg:pt-20 lg:max-w-1/2 lg:mx-auto">
      <template v-if="wallet">
        <div
          class="hidden h-24"
          :class="wallet ? 'lg:block' : 'lg:flex items-center'"
        >
          <div
            class="w-full flex items-center justify-between"
            :class="{ 'mt-4': wallet }"
          >
            <p class="text-xl text-left text-grey-1 font-bold">
              <span v-if="!wallet">{{ $t('home.welcome') }}</span>
              <!-- <span v-else>{{ name }}</span> -->
            </p>
          </div>
        </div>
        <div
          :class="{
            'green-gradient-bg': screen.width < 1024,
            'bg-green-7': screen.width >= 1024,
            'pb-8': isPromoter && false //TODO
          }"
          class="relative px-6 mb-8 lg:rounded-2xl pt-4 lg:pt-8 bg-cover bg-center"
          :style="
            promoterWallets && loans.length === 1 && loans[0].image
              ? 'box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.4); background-image: url(\'' +
                loans[0].image.path +
                '\')'
              : loans.length === 1 && !loans[0].image
              ? 'box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.2);'
              : ''
          "
        >
          <img
            src="@/assets/svg/green_ball.svg"
            alt="ball"
            class="absolute top-0 right-1/3 hidden lg:block"
            v-if="!isPromoter || loans.length > 1"
          />
          <div
            v-if="singleLoan"
            class="text-white text-3xl font-medium text-left"
          >
            {{ loans[0].name }}
          </div>
          <RiskAndCategory
            v-if="singleLoan"
            :risk="loans[0].risk"
            :category="loans[0].category"
            class="absolute right-6 top-9"
            horizontal
          />
          <!-- <div
            class="w-full flex items-center justify-between lg:hidden"
          >
            <div
              v-if="wallet"
              @click="changeWallet()"
              class="flex items-center"
            >
              <img src="@/assets/svg/arrows/left_grey_arrow.svg" alt="back" />
              <p
                class="text-grey-2 text-lg font-bold ml-2"
                :class="{
                  'text-white': promoterWallets && loans.length > 1
                }"
              >
                {{ name }}
              </p>
            </div>
          </div> -->
          <div class="lg:pt-0 pt-30 text-left">
            <!-- Total assets -->
            <div
              v-if="!isPromoter"
              :class="singleLoan ? 'text-white' : 'text-grey-2'"
            >
              <p class="text-opacity-80 font-medium mb-2">
                {{ $t('home.total_assets') }}
              </p>
              <p
                class="text-5xl mb-12 lg:mb-6"
                :class="{ 'text-opacity-20': !totalAssets }"
              >
                <span class="font-bold">${{
                  dollarFormatting(totalAssets + ((walletMetrics.pending_balance > 0) ? walletMetrics.pending_balance : 0))
                }}</span>
                <!-- <span
                  >.{{
                    dollarFormatting(totalAssets).split('.')[1] ?? '00'
                  }}
                </span> -->
              </p>
            </div>
            <div v-else>
              <p
                class="font-medium mb-2"
                :class="{
                  'text-white': promoterWallets && loans.length === 1,
                  'text-grey-2 text-opacity-80':
                    !promoterWallets || loans.length > 1
                }"
              >
                {{ $t('home.balance', isPromoter && loans.length > 1 ? 0 : 1) }}
              </p>
              <p
                class="text-5xl mb-12"
                :class="{
                  'text-opacity-20': !balanceCents,
                  'text-white': promoterWallets && loans.length === 1,
                  'text-grey-2': !promoterWallets || loans.length > 1
                }"
              >
                <span class="font-bold">${{
                  dollarFormatting(balanceCents + ((walletMetrics.pending_balance > 0) ? walletMetrics.pending_balance : 0))
                }}</span>
                <!-- <span
                  >.{{
                    dollarFormatting(balanceCents).split('.')[1] ?? '00'
                  }}
                </span> -->
              </p>
            </div>
            <!-- Wallet list -->
            <!-- <div
              v-if="wallets.length > 1 && !wallet && !isPromoter"
              class="pb-4"
            >
              <WalletListElement
                v-for="(wallet, index) of wallets"
                :key="wallet.id"
                :wallet="wallet"
                :isLast="index === wallets.length - 1"
                @click="changeWallet(wallet.id)"
              />
            </div> -->
            <!-- Single wallet data -->
            <div class="lg:pb-6">
              <div
                v-if="!isPromoter"
                class="w-full flex justify-between mb-8 lg:mb-0 text-white"
                :class="singleLoan ? 'text-white' : 'text-grey-2'"
              >
                <div class="w-1/3 text-left">
                  <p
                    class="text-13 text-opacity-80 mb-2"
                    v-html="$t('home.current_invested')"
                  />
                  <p class="font-bold text-opacity-80 lg:text-2xl">
                    ${{ dollarFormatting(walletMetrics.current_invested ?? 0) }}
                  </p>
                </div>
                <div class="w-1/3 text-left">
                  <p
                    class="text-13 text-opacity-80 mb-2"
                    v-html="$t('home.interest_earned')"
                  />
                  <p class="font-bold text-opacity-80 lg:text-2xl">
                    ${{ dollarFormatting(walletMetrics.interest_earned ?? 0) }}
                  </p>
                </div>
                <div class="w-1/3 text-left">
                  <p
                    class="text-13 text-opacity-80 mb-2"
                    v-html="$t('home.wallet_balance')"
                  />
                  <div class="relative inline-block">
                    <p class="font-bold text-opacity-80 lg:text-2xl">
                      ${{ dollarFormatting((walletMetrics.balance ?? 0) + ((walletMetrics.pending_balance > 0) ? walletMetrics.pending_balance : 0)) }}
                    </p>
                    <p v-if="walletMetrics.pending_balance" class="absolute -top-1 -right-5">
                      <InfoTooltip 
                        :copy="'home.pending_balance'" 
                        :positionXAxisMobile="'-left-72'"
                        :value="dollarFormatting(walletMetrics.pending_balance)"
                        />
                    </p>
                  </div>
                </div>
              </div>
              <div class="pb-4 lg:absolute lg:top-8 right-8">
                <div class="flex lg:justify-end">
                  <GpButton
                    color="grey"
                    desktopWidth="w-32"
                    mobileWidth="w-32"
                    @click="goToTopup"
                    :disabled="!profile.activated || !profile.can_check || !profile.lend_ack || profile.sub_type ==='unsuitable' || profile.sub_type ==='unsuitable_pending'"
                    v-if="!isPromoter && !reservationsOnly"
                  >
                    {{ '+ ' + $t('home.topup') }}
                  </GpButton>

                  <div
                    v-if="!isPromoter && !reservationsOnly"
                    class="
                      ml-4
                      bg-grey-2 bg-opacity-20
                      rounded-2xl
                      flex
                      items-center
                      justify-center
                      cursor-pointer
                      relative
                      px-px
                    "
                    :class="{ 'z-30': !showDrawer }"
                    @click="toggleDrawer"
                  >
                    <img
                      src="@/assets/svg/3_dots_dark.svg"
                      alt="dots"
                      class="mx-4"
                    />
                  </div>
                  <Drawer
                    class="lg:hidden"
                    v-if="showDrawer"
                    minHeight="h-auto"
                    @close="() => (showDrawer = false)"
                  >
                    <template v-slot:content>
                      <HomeDrawer
                        @close="() => (showDrawer = false)"
                        :hasMoney="walletMetrics.balance !== 0"
                        :isStrategy="walletMetrics.saving_id !== null"
                        :strategyId="walletMetrics.saving_id"
                        :strategyName="walletMetrics.name"
                        :wallet="walletMetrics"
                        :strategiesCount="strategies ? strategies.length : 0"
                        :autoinvestmentsCount="autoinvestments"
                        :activeStrategy="strategies ?
                          strategies.filter((s) => {
                            return s.id === walletMetrics.saving_id
                          }).length : null
                        "
                      />
                    </template>
                  </Drawer>
                </div>
                <div
                  v-if="showDrawer"
                  class="
                    hidden
                    lg:block
                    relative
                    bg-white
                    rounded-2xl
                    ml-auto
                    mt-2
                  "
                >
                  <HomeDrawer
                    :hasMoney="walletMetrics.balance !== 0"
                    :isStrategy="walletMetrics.saving_id !== null"
                    :strategyId="walletMetrics.saving_id"
                    :strategyName="walletMetrics.name"
                    :wallet="walletMetrics"
                    :strategiesCount="strategies ? strategies.length : 0"
                    :autoinvestmentsCount="autoinvestments"
                    :activeStrategy="
                      strategies ? strategies.filter((s) => {
                        return s.id === walletMetrics.saving_id
                      }).length : null
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- <img
            v-if="!investmentCount"
            class="absolute bottom-0 right-0 lg:hidden"
            src="@/assets/img/home_plant.png"
            alt="plant"
          /> -->
          <NextPaymentCard
            v-if="isPromoter && singleLoan && loans[0].next_payment"
            :daysLeft="loans[0].next_payment.days_to_next_payment"
            :amount="loans[0].next_payment.amount"
            :id="loans[0].id"
            :type="
              singleLoanArrears
                ? 'arrears'
                : !loans[0].next_direct_debit
                ? 'default'
                : loans[0].next_direct_debit.status ?? 'default'
            "
            :arrearsDate="loans[0].loan_status.in_arrears_since"
          />
        </div>
      </template>
      <div v-else-if="!wallet && !isBorrower">
        <div class="hidden lg:block mb-4">
          <div class="project-card project-card--skeleton h-14 my-4 w-1/2">
            <div
              class="
                project-card__header project-card__header--skeleton
                h-full
                rounded-3xl
              "
            ></div>
          </div>
          <div class="project-card project-card--skeleton h-60 mt-8">
            <div
              class="
                project-card__header project-card__header--skeleton
                h-full
                rounded-3xl
              "
            ></div>
          </div>
        </div>
        <div class="block lg:hidden pt-6 px-6">
          <div class="project-card project-card--skeleton h-24 mt-12 mb-12">
            <div
              class="
                project-card__header project-card__header--skeleton
                h-full
                rounded-3xl
              "
            ></div>
          </div>
          <TransactionSkeleton
            v-for="index in 2"
            v-bind:key="'transaction-skeleton-' + index"
            class="mb-2"
          />
        </div>
      </div>
      <div v-if="isPromoter">
        <div class="container sm:px-6 lg:px-0 sm:max-w-none">
          <LoanCircle
            class="mb-6"
            v-if="singleLoan"
            :total="loans[0].loan_status.loan_amount"
            :arrears="loans[0].loan_status.in_arrears"
            :amount="loans[0].loan_status.capital_paid"
            :outstanding="loans[0].loan_status.capital_outstanding"
          />
        </div>
        <div class="sm:px-6 lg:px-0">
          <LoanCarousel
            :projects="
              orderedDashboardLoans ? orderedDashboardLoans.slice(0, 4) : []
            "
            :total="loans.length"
            :skeleton="!fetchedLoans"
            class="mb-12"
          />
        </div>
        <div class="container sm:px-6 lg:px-0 sm:max-w-none">
          <OverdueWarning
            v-if="hasLoanArrears && !singleLoanArrears"
            class="mb-4"
          />
          <SingleOverdueWarning
            v-if="singleLoanArrears && nextDD && loans.length"
            class="mb-4"
            :loan="loans[0]"
          />
          <div
            v-if="!hasLoanArrears"
            @click="goToDirectDebitDetails()"
            class="cursor-pointer"
          >
            <NextDD
              class="mb-4"
              :state="nextDD.status"
              :amount="nextDD.amount"
              :fee="dollarFormatting(nextDD.failed_dd_fee)"
              :days="nextDD.status != 'SUCCEEDED' ? nextDD.days_left : '8'"
              :date="nextDD.execution_date"
              v-if="nextDD"
            />
          </div>

          <!-- <NextDDWarning
            v-if="nextDD && (!nextDD.status || nextDD.status === 'CREATED')"
            class="mb-4"
          /> -->
          <FeeWarning
            :amount="loans[0].next_payment.amount - balanceCents"
            :date="loans[0].next_payment.next_payment_date"
            v-if="
              loans &&
              loans.length === 1 &&
              balanceCents < loans[0].next_payment.amount &&
              !nextDD
            "
            class="mb-4"
          />
          <GpButton
            color="grey"
            class="mt-4 mx-auto"
            desktopWidth="w-64"
            mobileWidth="w-full"
            @click="goToTopup"
            v-if="
              (loans &&
                loans.length === 1 &&
                balanceCents < loans[0].next_payment.amount &&
                !nextDD) ||
              (nextDD && nextDD.status === 'FAILED')
            "
          >
            {{ '+ ' + $t('home.topup_long') }}
          </GpButton>
        </div>
      </div>
      <div class="py-4 px-6 lg:px-0 bg-green-8">
        <!-- popups -->
        <FinishSuitability v-if="!isBorrower"/>
        <KycConditions />
        <BankAccountKyc v-if="isBorrower"/>
        <SuitabilityInfo />

        <!-- <template v-if="strategies">
          <TemporaryPopup
            v-if="isKycRegular && strategies.length === 0 && !isPromoter"
            name="home:kyc"
            :closeable-times="3"
            copy="home.strategy_popup"
            icon="/img/popup/strategy_dark.svg"
            btn-link="strategies/add-strategy"
          />
        </template> -->

        <!-- Open Projects -->
        <!-- <template v-if="!isPromoter">
          <div class="w-full flex items-center justify-between my-6">
            <p v-if="!isBorrower" class="text-grey-2 text-opacity-50">
              {{ $t('home.open_projects') }}
            </p>
            <p v-else class="text-grey-2 text-opacity-50">
              {{ $t('home.open_projects_borrower') }}
            </p>
            <router-link
              v-if="projects && projects.length"
              class="grey-link"
              :to="'/projects'"
            >
              {{ capitalize($t('wording.see_all')) }}
              <img
                src="@/assets/svg/arrows/right_dark_grey_arrow.svg"
                alt="right"
                class="ml-1"
              />
            </router-link>
          </div>
          <ProjectCarousel
            :projects="projects"
            class="-mx-6 sm:mx-0"
            :skeleton="fetching"
          />
        </template> -->

        <!-- Dynamic card -->
        <!-- <div
          class="w-full rounded-2xl my-12 p-4 relative overflow-hidden"
          :style="{ 'background-color': '#f8eccf' }"
          v-if="!isPromoter && avgInterestRate"
        >
          <p class="text-sm text-grey-2 text-left mb-4 relative z-30">
            {{ $t('home.did_you_know.title') }}
          </p>
          <p
            class="
              text-xl
              font-medium
              text-grey-2 text-left
              w-4/5
              mb-3
              relative
              z-30
            "
          >
            {{ $t('home.did_you_know.content') }}&nbsp;{{roundToOneDecimal(avgInterestRate, 1)}}%
          </p>
          <router-link to="/projects">
            <GpButton
              class="ml-auto relative z-30"
              color="white"
              desktopWidth="w-1/2"
              mobileWidth="w-1/2"
            >
              {{ $t('home.did_you_know.btn') }}
            </GpButton>
          </router-link>
          <img
            class="absolute top-0 right-0 z-20"
            src="@/assets/svg/orange-ball-1.svg"
            alt="ball"
          />
          <img
            class="absolute bottom-0 left-0 z-20"
            src="@/assets/svg/orange-ball-2.svg"
            alt="ball"
          />
        </div> -->

        <InviteBanner v-if="profile.beta_tester && !isBorrower" class="mt-4"/>
        <GiftcardsBanner class="mt-8" v-if="isKycRegular && !isBorrower"/>
        <div class="mt-6 mb-8" v-if="isKycRegular && !isBorrower">
          <div class="block md:hidden text-left text-grey-2 text-base opacity-60">
            {{$t('voucher_mobile.have_voucher')}}
          </div>
          <router-link :to="'/vouchers/redeem'">
            <div class="bg-white w-full p-4 mt-3 rounded-2xl text-left text-grey-3 font-semibold text-sm flex justify-between">
              <div>
              {{$t('voucher_mobile.redeem')}}
              </div>
              <img
                v-if="!white"
                src="@/assets/svg/arrows/right_grey_arrow.svg"
                alt=""
              />
            </div>
          </router-link>
        </div>

        <!-- Referral popup -->
        <!-- <TemporaryPopup
          v-if="isKycRegular && !isPromoter"
          name="home:referral"
          :closeable-times="3"
          copy="home.referral_popup"
          icon="/img/popup/referral.svg"
          btn-link="vouchers/invite"
          class="mb-12"
        /> -->
        <!-- Contracts -->
        <template v-if=" !isPromoter && (contracts && contracts.length)">
          <div class="w-full flex items-center justify-between mt-6 mb-6">
            <p class="text-grey-2 text-left text-opacity-50">
              {{ $t('home.contracts') }}
            </p>
            <router-link class="orange-link" :to="'/contracts'">
              {{ capitalize($t('wording.see_all')) }}
              <img
                src="@/assets/svg/arrows/left_orange_arrow.svg"
                alt="right"
                class="mr-1"
                style="transform: scaleX(-1);"
              />
            </router-link>
          </div>

          <ContractCarousel
            :contracts="contracts"
            class="-mx-6 sm:mx-0"
            :skeleton="!contracts"
          />
        </template>
          <!-- Transactions -->
        <div class="w-full flex items-center justify-between mb-6 mt-12">
          <p class="text-grey-2 text-left text-opacity-50">
            {{ $t('home.latest_activity') }}
          </p>
          <router-link class="orange-link flex" :to="'/transactions'">
            <div>
              {{ capitalize($t('wording.see_all')) }}
            </div>
            <img
              src="@/assets/svg/arrows/left_orange_arrow.svg"
              alt="right"
              class="mr-1"
              style="transform: scaleX(-1);"
            />
          </router-link>
        </div>
        <div
          v-if="transactions && !transactions.length"
          class="
            bg-white bg-opacity-60
            lg:bg-transparent
            rounded-2xl
            py-4
            lg:p-0
            mb-8
          "
        >
          <TransactionsListElement
            :transaction="{ type: 'empty' }"
            isLast
            :privacyMode="privacyMode"
          />
        </div>
        <div
          v-else-if="transactions && transactions.length"
          class="bg-white lg:bg-transparent rounded-2xl py-4 lg:p-0 mb-8"
        >
          <TransactionsListElement
            v-for="(transaction, index) of transactions"
            :key="transaction.id"
            :transaction="transaction"
            :isLast="index + 1 === transactions.length"
            class="mb-2"
          />
        </div>
        <div v-else>
          <TransactionSkeleton
            v-for="index in 3"
            v-bind:key="'transaction-skeleton-' + index"
            class="mb-2"
          />
        </div>

        <!-- Promoter -->
        <div v-if="isPromoter" class="my-16">
          <!-- <NewLoanButton class="my-16" /> -->
          <AccountManager
            :manager="{
              name: 'Blake Bunting',
              photo: 'blake.png',
              email: 'operations-ca@goparity.com'
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, inject, onBeforeMount } from 'vue'
import Drawer from '@/components/general/Drawer.vue'
import HomeDrawer from '@/components/dashboard/HomeDrawer.vue'
import { useStore } from 'vuex'
import GpButton from '@/components/general/GpButton.vue'
import InfoTooltip from '@/components/general/InfoTooltip.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import TransactionsListElement from '@/components/transactions/TransactionListElement.vue'
import TransactionSkeleton from '@/components/transactions/TransactionSkeleton.vue'
// import WalletListElement from '@/components/dashboard/WalletListElement.vue'
// import ProjectCarousel from '@/components/projects/Carousel.vue'
import ContractCarousel from '@/components/contracts/ContractCarousel.vue'
import { useI18n } from 'vue-i18n'
// import TemporaryPopup from '@/components/dashboard/TemporaryPopup.vue'
import FinishSuitability from '@/components/general/FinishSuitability.vue'
import KycConditions from '@/components/general/KycConditions.vue'
// import BankAccountKyc from '@/components/general/BankAccountKyc.vue'
import InviteBanner from '@/components/invite/InviteBanner'
import SuitabilityInfo from '@/components/general/SuitabilityInfo.vue'
import AccountManager from '@/components/promoter/AccountManager'
import LoanCircle from '@/components/promoter/LoanCircle'
import LoanCarousel from '@/components/promoter/LoanCarousel'
// import NewLoanButton from '@/components/promoter/NewLoanButton'
import { useRouter, useRoute } from 'vue-router'
// import NextDDWarning from '@/components/promoter/NextDDWarning'
import NextDD from '@/components/promoter/NextDD'
import NextPaymentCard from '@/components/promoter/NextPaymentCard'
import FeeWarning from '@/components/promoter/FeeWarning'
import OverdueWarning from '@/components/promoter/OverdueWarning'
import SingleOverdueWarning from '@/components/promoter/SingleOverdueWarning'
import RiskAndCategory from '@/components/projects/RiskAndCategory'
import GiftcardsBanner from "@/components/giftcards/GiftcardsBanner";

export default {
  components: {
    RiskAndCategory,
    OverdueWarning,
    TransactionSkeleton,
    SingleOverdueWarning,
    GiftcardsBanner,
    FeeWarning,
    NextPaymentCard,
    // NextDDWarning,
    NextDD,
    LoanCarousel,
    // NewLoanButton,
    InviteBanner,
    LoanCircle,
    // ProjectCarousel,
    ContractCarousel,
    AccountManager,
    TransactionsListElement,
    KycConditions,
    FinishSuitability,
    // BankAccountKyc,
    Drawer,
    HomeDrawer,
    // TemporaryPopup,
    GpButton,
    SuitabilityInfo,
    InfoTooltip
  },
  setup() {
    const store = useStore()
    const showDrawer = ref(false)
    const router = useRouter()
    const route = useRoute()
    const { dollarFormatting, capitalize, roundToOneDecimal, maskEmail } = useGlobalHelpers()
    const i18n = useI18n({ useScope: 'global' })
    const allLoaded = ref(false)
    const fetchedContracts = ref(false)
    const show = ref(true)
    
    const reservationsOnly = computed(() => {
      return process.env.VUE_APP_RESERVATIONS_ONLY == 'true'
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const account = computed (() => {
      return store.state.bankAccounts.account
    })

    onBeforeMount( () => {  
      // Check if the component has been shown in this session
      const componentShown = sessionStorage.getItem('componentShown');

      if (componentShown) {
        show.value = false;
      } else {
        // Set a flag in sessionStorage to indicate the component has been shown
        sessionStorage.setItem('componentShown', true);
        show.value = true;
      }
    })

    onMounted(async () => {
      await store.dispatch('fetchProfile')
      await store.dispatch('fetchPopup')
      if(!isBorrower.value) await store.dispatch('fetchSuitability')

      if(route.query && route.query['boughtgift']){
        const popup = {
          title: i18n.t('giftcards.popup.title'),
          align: 'center',
          side: false,
          iconBg: 'bg-green-5',
          icon: '/img/done_step.svg',
          content: i18n.t('giftcards.popup.desc'),
          primaryButton: i18n.t('giftcards.popup.btn')
        }
        store.dispatch('setPopup', popup)
      }
      else if(profile.value && profile.value.email){
        // 1. unsigned lend ack
        if(!profile.value.lend_ack && !isBorrower.value && profile.value.sub_type !== 'unsuitable_pending'){
            const popup = {
              align: 'left',
              side: false,
              component: 'LendWarning',
              docFormat: true,
              disableClose: true,
              bgColor: 'gp-background',
              props: { profile: profile.value }
            }
            store.dispatch('setPopup', popup)
        }

        // 2. check if activated
        else if(!profile.value.activated ){
          const popup = {
            align: 'center',
            icon: '/img/popup/exclamation_orange.svg',
            component: 'Activate',
            props: { email: maskEmail(profile.value.email) }
          }
          store.dispatch('setPopup', popup)
        }
        
        // 3. falied canCheck
        else if(!profile.value.can_check ){
          const popup = {
            align: 'center',
            icon: '/img/popup/exclamation_orange.svg',
            component: 'DashboardPopup',
          }
          store.dispatch('setPopup', popup)
        }

        // 4. expired suitability
        else if(profile.value.expired_suitability && profile.value.sub_type !== 'unsuitable_pending' && !isBorrower.value){
          const popup = {
            align: 'center',
            icon: '/img/popup/exclamation_orange.svg',
            component: 'DashboardPopup',
          }
          store.dispatch('setPopup', popup)
        }
    
        // 5. kicked from site (unsuitable & reviewed)
        else if(profile.value.sub_type === 'unsuitable' && profile.value.dealer_approved) {
          const popup = {
            align: 'center',
            icon: '/img/popup/exclamation_orange.svg',
            component: 'UnsuitableWarning',
            props: { profile: profile.value }
          }
          store.dispatch('setPopup', popup)
        }

        // personal popup
        else if(personalPopup.value && show.value){
          if(personalPopup.value ){
            const popup = {
              align: 'center',
              icon: '/img/popup/exclamation_orange.svg',
              component: 'PersonalPopup',
              props: { title: personalPopup.value.title, content: personalPopup.value.content }
            }
            store.dispatch('setPopup', popup)
          }
    
        // // 6. not DR approved 
        // else if(!profile.value.dealer_approved ){
        //   if(!cookies.getCookie('showed_welcome')) {
        //     const popup = {
        //       align: 'center',
        //       icon: '/img/popup/exclamation_orange.svg',
        //       component: 'DashboardPopup',
        //     }
        //     store.dispatch('setPopup', popup)
        //     cookies.setCookie('showed_welcome', true)
        //   }
        }
      }
        
      fetchedContracts.value = false
      if (profile.value.blocked_country)
        store.dispatch('setToast', {
          type: 'warning',
          toast: 'blockedUser',
          position: 'top-right'
        })
      await store.dispatch('setFetching', true)
      if (isPromoter.value) await store.dispatch('fetchLoans')
      else await store.dispatch('setType', 'projects')
      // Get wallets
      await store.dispatch('fetchWallets')
      // Get strategies
      // await store.dispatch('fetchStrategies')
      // Get auto-investments
      // await store.dispatch('fetchAutoInvestments')
      // get projects
      if (!isPromoter.value) {
        store.dispatch('getProjects')
      }

      // Get metrics
      if(!store.state.metrics.metrics) await store.dispatch('fetchMetrics')

      // Get contracts
      await store.dispatch('fetchContracts')
      fetchedContracts.value = true
      // Get transactions
      await store.dispatch('resetNextPage')
      await store.dispatch('fetchTransactions', {
        page: 1,
        total: 3,
        group: false
      })
      allLoaded.value = true
      if (isPromoter.value) {
        if (hasLoanArrears.value) {
          store.dispatch('setToast', {
            type: 'warning',
            toast: 'arrears',
            position: 'top-right'
          })
        }
        if (
          nextDD.value &&
          nextDD.value.status === 'FAILED' &&
          !hasLoanArrears.value
        ) {
          store.dispatch('setToast', {
            type: 'warning',
            toast: loans.value.length === 1 ? 'failedDD' : 'multipleFailedDD',
            vars: {
              days: orderedLoans.value[0]
                ? orderedLoans.value[0].next_payment.days_to_next_payment
                : 0,
              fee: dollarFormatting(nextDD.value.failed_dd_fee)
            },
            position: 'top-right'
          })
        }
      }
    })

    const isPromoter = computed(() => {
      return profile.value.promoter_id ? true : false
    })

    const wallet = computed(() => {
      return store.state.wallets.wallet
    })

    const isBorrower = computed(() => {
      return profile.value && profile.value.sub_type === 'borrower'
    })

    const fetchedLoans = computed(() => {
      return store.state.promoters.fetchedLoans
    })

    const fetching = computed(() => {
      return store.state.projects.fetching
    })

    // const avgInterestRate = computed(() => {
    //   return store.state.metrics.metrics && store.state.metrics.metrics.avg_interest_rate ? store.state.metrics.metrics.avg_interest_rate : null
    // })

    // const promoterWallets = computed(() => {
    //   return true
    // })

    const personalPopup = computed(() => {
      return store.state.popups.popup
    })
    
    const projects = computed(() => {
      return store.state.projects.projects
    })

    const loans = computed(() => {
      return store.state.promoters.loans
    })

    const nextDD = computed(() => {
      return store.getters.getNextDD()
        ? store.getters.getNextDD().next_direct_debit
        : null
    })

    const transactions = computed(() => {
      return store.state.transactions.transactionSample
    })

    const contracts = computed(() => {
      return store.state.contracts.homepageContracts
    })

    const strategies = computed(() => {
      return store.state.strategies.strategies
    })

    const autoinvestments = computed(() => {
      return store.getters.getActiveAutoInvestment ?? 0
    })

    const balanceCents = computed(() => {
      let total = 0

      if (isPromoter.value) {
        if (loans.value.length === 1)
          total = loans.value[0].project_wallet.balance
        else total = totalCapitalOutstanding.value
      } else {
        total = total + store.state.wallets.wallet.value.balance
      }
      return total
    })

    const totalCapitalOutstanding = computed(() => {
      return loans.value
        ? loans.value.reduce((accumulator, object) => {
            return accumulator + object.loan_status.capital_outstanding
          }, 0)
        : null
    })

    const orderedDashboardLoans = computed(() => {
      return store.getters.orderedDashboardLoans()
    })

    const orderedLoans = computed(() => {
      if (loans.value) {
        let loansCopy = JSON.parse(JSON.stringify(loans.value))
        loansCopy.sort(function (a, b) {
          return (
            new Date(
              a.next_direct_debit
                ? a.next_direct_debit.execution_date
                : '01/01/97'
            ) -
            new Date(
              b.next_direct_debit
                ? b.next_direct_debit.execution_date
                : '01/01/97'
            )
          )
        })
        return loansCopy
      }
      return null
    })

    const investmentCount = computed(() => {
      return wallet.value ? wallet.value.investment_count : null
    })

    const isKycRegular = computed(() => {
      return profile.value.kyc_level === 'regular' ? true : false
    })

    const walletMetrics = computed(() => {
      return wallet.value
    })

    const totalAssets = computed(() => {
      return wallet.value ? walletMetrics.value.total_assets : 0
    })

    const singleLoan = computed(() => {
      return loans.value && loans.value.length === 1
    })

    const singleLoanArrears = computed(() => {
      return (
        loans.value &&
        loans.value.length === 1 &&
        loans.value[0].loan_status.in_arrears > 0
      )
    })

    const hasLoanArrears = computed(() => {
      return loans.value.some((el) => {
        return el.loan_status.in_arrears > 0
      })
    })

    const name = computed(() => {
      return (
        walletMetrics.value.name ?? i18n.t('transactions.filters.main_wallet')
      )
    })

    function goToTopup() {
      router.push({
        name: 'topup',
        params: {
          propWallet: JSON.stringify(walletMetrics.value)
        }
      })
    }

    function goToDirectDebitDetails() {
      router.push({
        name: 'direct-debit-details'
      })
    }

    const toggleDrawer = () => {
      showDrawer.value = !showDrawer.value
    }

    return {
      capitalize,
      projects,
      account,
      singleLoan,
      transactions,
      isKycRegular,
      showDrawer,
      fetchedLoans,
      balanceCents,
      strategies,
      dollarFormatting,
      goToTopup,
      singleLoanArrears,
      isPromoter,
      isBorrower,
      walletMetrics,
      wallet,
      fetching,
      nextDD,
      name,
      screen,
      show,
      totalCapitalOutstanding,
      toggleDrawer,
      contracts,
      loans,
      orderedDashboardLoans,
      hasLoanArrears,
      profile,
      personalPopup,
      goToDirectDebitDetails,
      fetchedContracts,
      allLoaded,
      autoinvestments,
      roundToOneDecimal,
      totalAssets,
      investmentCount,
      reservationsOnly
    }
  }
}
</script>

<style lang="scss" scoped></style>
