<template>
  <div class="page-no-nav flex flex-col items-end">
    <SideBar v-if="wallet && project">
      <ProjectData :project="project" />
    </SideBar>
    <div class="strategies__desktop-container">
      <Form
        v-if="wallet && project"
        class="
          form
          flex-grow flex flex-col
          justify-between
          lg:w-3/4
          xl:w-2/3
          2xl:w-1/2
        "
        ref="form"
        v-slot="{ errors, meta }"
        @submit="() => {}"
      >
        <div>
          <Back :to="-2" :title="$t('invest.amount.title')" />
          <p
            class="
              text-sm text-grey-2 text-opacity-80 text-left
              mb-8
              mt-6
              lg:mt-2
            "
          >
            {{ $t('invest.amount.text') }}
            <span class="font-bold">{{ project.name }}?</span>
          </p>
          <p class="text-sm text-grey-2 text-opacity-80 text-left mb-8">
            {{ $t('invest.amount.limit') }}
            <span class="font-bold">${{ dollarFormatting(maxInvestment) }}</span>
          </p>
          <GeneralInput
            noMargin
            class="mb-4"
            v-model:data="amount"
            type="dollar"
            name="amount"
            :label="$t('invest.amount.amount')"
            :locked="investVoucherFully ? true : false"
            :error="!investVoucherFully && errors.amount"
            :rules="'required|minValue:'+minInvestment+'|maxValue:'+maxInvestment+'|multiplier:1000'"
          />
          <!-- Already invested -->
          <div
            v-if="investments && investments.length"
            class="flex w-full items-center justify-between"
          >
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('invest.amount.already_invested') }}
            </p>
            <p class="text-sm font-semibold text-grey-2">
              ${{ dollarFormatting(investedSum) }}
            </p>
          </div>
          <div
            v-if="currentInvested > 0"
            class="flex w-full items-center justify-between mt-1 mb-1 text-left"
          >
            <p class="text-sm text-grey-2 text-opacity-80 mr-4 lg:mr-0">
              {{ $t('invest.amount.already_invested_12') }}
            </p>
            <p class="text-sm font-semibold text-grey-2">
              ${{dollarFormatting(currentInvested)}}
            </p>
          </div>
          <div
            v-if="vouchers && vouchers.length && filteredVouchers"
            class="flex w-full items-center justify-between mt-1 mb-1 text-left"
          >
            <p class="text-sm text-grey-2 text-opacity-80 mr-4 lg:mr-0">
              {{ $t('invest.amount.voucher') }}
            </p>
            <p class="text-sm font-semibold text-grey-2">
              ${{ dollarFormatting(filteredVouchers) }}
            </p>
          </div>
          <GeneralInput
            v-if="displayInvestVoucherWithoutInvestment"
            v-model:data="investVoucherFully"
            type="checkbox"
            name="investVoucherFully"
            class="my-4"
          >
            <template #label>
              {{ $t('vouchers.redeem.invest_voucher_fully') }}
            </template>
          </GeneralInput>
          <!-- Waiting list disclaimer -->
          <GeneralInput
            v-if="project.state === 'reserved'"
            v-model:data="waitingListCheck"
            :label="$t('invest.amount.waiting_list_disclaimer')"
            type="checkbox"
            name="waitingListCheck"
            class="mt-6"
          />
          <!-- general ack-->
          <p v-html="$t('invest.amount.risk_disclaimer', {link: link})"
          class="text-sm text-grey-3 text-left p-4"
          target="_blank"/>

          <GeneralInput
            v-model:data="agree"
            :label="$t('invest.amount.agree')"
            type="checkbox"
            :rules="'required'"
            name="agree"
            class="mt-6"
          />

        </div>
        <div class="button-padding-bottom">
          <div class="flex items-center justify-between mb-4">
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('invest.amount.wallet_balance') }}
            </p>
            <p class="font-semibold text-sm text-grey-2">
              ${{ dollarFormatting(wallet.balance ?? 0) }}
            </p>
          </div>
          <p class="text-13 text-grey-2 text-opacity-60 text-left mb-8">
            {{ $t('invest.amount.topup_text') }}
          </p>
          <GpButton
            class="ml-auto"
            :disabled="formCompleted(meta.valid) || !agree || !profile.can_check || !profile.lend_ack"
            @click="confirm"
            :color="
              project.state === 'reserved' || profile.kyc_level === 'light'
                ? 'grey'
                : 'orange'
            "
          >
            {{ $t('wording.continue') }}
          </GpButton>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import ProjectData from '@/components/invest/ProjectData.vue'
import SideBar from '@/components/general/SideBar.vue'
import GpButton from '@/components/general/GpButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import { Form } from 'vee-validate'
import GeneralInput from '@/components/general/Input.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import useFlows from '@/mixins/useFlows.js'
import axios from 'axios'
export default {
  components: {
    Back,
    GpButton,
    Form,
    GeneralInput,
    SideBar,
    ProjectData
  },
  props: {
    simulationAmount: {
      type: String,
      default: ''
    },
    editReservation: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const route = useRoute()
    const store = useStore()
    const router = useRouter()
    const amount = ref(
      props.simulationAmount !== '' ? props.simulationAmount : null
    )
    const { dollarFormatting } = useGlobalHelpers()
    const currentInvested = ref(0)
    const { resetInvestFlow } = useFlows()
    const form = ref(null)
    const waitingListCheck = ref(false)
    const agree = ref(false)
    const settingsConfig = require("@/config/settings.config.js");

    const vouchers = ref(null)
    const minInvestmentVoucherValue = ref(false)
    const investVoucherFully = ref(false)

    const formCompleted = (meta) => {
      if (investVoucherFully.value) {
        amount.value = null
        return false
      }
      if (amount.value && meta) return false
      return true
    }

    const wallet      = computed(() => {
      return store.state.wallets.wallet
    })
    const project     = computed(() => {
      return store.state.projects.project
    })
    const investments = computed(() => {
      return store.getters.getPendingInvestments
    })
    const profile     = computed(() => {
      return store.getters.getProfile()
    })
    const settings    = computed(() => {
      return store.state.general.settings
    })
    const suitability = computed(() => {
      return store.state.suitability.suitability
    })

    const displayInvestVoucherWithoutInvestment = computed(() => {
      return minInvestmentVoucherValue.value === null ?? true
    })

    const filteredVouchers = computed(() => {
      const amt = amount.value
        ? parseFloat(amount.value.replace(/\./g, '').replace(/,/g, '.')) * 100
        : 0
      const vchr = vouchers.value
        ? vouchers.value.filter((el) => {
            return amt >= el.minimum_investment
          })
        : null

      return vchr
        ? vchr.reduce(function (acc, obj) {
            return acc + obj.value
          }, 0)
        : 0
    })

    const investedSum = computed(() => {
      let total = 0
      investments.value.map((i) => {
        total += i.total
      })
      return total
    })
    const investmentLimit = computed(() => {
      return settings.value && settings.value.vp_investment_limit ? settings.value.vp_investment_limit : 1000000
    })

    const lowLimit = computed(() => {
      return settings.value && settings.value.length_check_low ? settings.value.length_check_low : 3
    })

    const medLimit = computed(() => {
      return settings.value && settings.value.length_check_med ? settings.value.length_check_med : 5
    })

    const highLimit = computed(() => {
      return settings.value && settings.value.length_check_high ? settings.value.length_check_high : 8
    })
    resetInvestFlow(-2)

    onMounted(async () => {
      await store.dispatch('setRequestSettings', settingsConfig.toString())
      await store.dispatch('fetchSuitability')

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/investments/' + project.value.id + '/currentInvested').then((data)=>{
        currentInvested.value = data.data.current_invested
        vouchers.value = data.data.vouchers
          if (data.data.vouchers.length === 1)
            minInvestmentVoucherValue.value =
              data.data.vouchers[0].minimum_investment
      }).catch((e) => {
          store.dispatch('setLoading', false);
          store.dispatch('setError', e)
      })

      if(suitability.value &&  
        ((suitability.value.investment_term == '1_3_years' && project.value.funding_rate.duration_months/12 > lowLimit.value)|| 
        (suitability.value.investment_term == '3_5_years' && project.value.funding_rate.duration_months/12 > medLimit.value)||
        (suitability.value.investment_term == '5_8_years' && project.value.funding_rate.duration_months/12 > highLimit.value))
      )
        showLengthPopup()
    })

    if (props.simulationAmount !== '') {
      router.push({
        name: 'invest-confirmation',
        params: {
          amount: props.simulationAmount
        }
      })
    }
    function showLengthPopup() {
      const popup = {
        component: 'LengthWarning',
        disableClose: true,
        props: { profile: profile.value}}
      store.dispatch('setPopup', popup)
    }
    function showRiskPopup() {
        const popup = {
          align: 'left',
          side: false,
          component: 'RiskWarning',
          disableClose: true,
          docFormat: true,
          bgColor: 'gp-background',    
          props: { 
            profile: profile.value,
            amount: amount.value,
            project: project.value,
          }      
        }
        store.dispatch('setPopup', popup)
    }
    const waitingListChecked = computed(() => {
      if (project.value.state === 'reserved')
        if (waitingListCheck.value) return true
        else return false
      else return true
    })

    const confirm = async () => {
      showRiskPopup()
      if (
        displayInvestVoucherWithoutInvestment.value &&
        investVoucherFully.value
      ) {
        router.push({
          name: 'invest-confirmation',
          params: {
            amount: 0,
            editReservation: props.editReservation
          }
        })
      }
      router.push({
        name: 'invest-confirmation',
        params: {
          amount: amount.value ?? 0,
          editReservation: props.editReservation
        }
      })
    }

    // limits
    const subTypeLimits = computed(() => {
      let subType =
        profile.value.sub_type == 'retail' ||
        profile.value.sub_type == 'eligible_pending' ||
        profile.value.sub_type == 'accredited_pending'
          ? 'retail'
          : profile.value.sub_type

      // let region = profile.value.region.toLowerCase().replace(/ /g, '_')

      // can't invest if
      if (
        !profile.value.lend_ack ||
        isBorrower.value ||
        !profile.value.activated ||
        !profile.value.can_check ||
        ['unsuitable', 'unsuitable_pending'].includes(profile.value.sub_type)
      )
        return 0

      //find limits from the settings based on sub type
      if (settings.value) 
        return settings.value['default_max_12months_investments_' + subType]

      // Regional limits logic to be implemented with another sprint

      // if (
      //   settings.value &&
      //   settings.value[region + '_max_12months_investments_no_limits']
      // ) {
      //   return settings.value[region + '_max_12months_investments_no_limits']
      // } else if (
      //   settings.value &&
      //   settings.value[region + '_max_12months_investments_only_retail_limit']
      // ) {
      //   return subType == 'retail'
      //     ? settings.value[
      //         region + '_max_12months_investments_only_retail_limit'
      //       ]
      //     : settings.value['default_max_12months_investments_accredited']
      // } else if (settings.value)
      //   return settings.value['default_max_12months_investments_' + subType]

      return 0
    })

    const concentrationLimit = computed(() => {
      if (suitability.value && settings.value) {
        return (
          (suitability.value.assets_value ?? 0) * (settings.value.concentration ?? 0.1)
        )
      }
      return 0
    })

    const maxInvestment = computed(() => {
      
      return Math.floor(Math.min(
        (subTypeLimits.value - (wallet.value.current_invested ?? 0)), 
        investmentLimit.value ?? 0,
        (project.value.crowdlending_value - project.value.invested.confirmed) ?? 0,
        (concentrationLimit.value - (wallet.value.current_invested ?? 0))
      )/ 1000) * 1000
    })

    const minInvestment = computed(() => {
      return settings.value?.min_investment ?? 2000
    })
    const isBorrower = computed(() => {
      return profile.value && profile.value.sub_type === 'borrower'
    })

    return {
      route,
      amount,
      project,
      wallet,
      maxInvestment,
      minInvestment,
      dollarFormatting,
      showRiskPopup,
      agree,
      showLengthPopup,
      confirm,
      form,
      formCompleted,
      link: process.env.VUE_APP_RDI_LINK,
      settings,
      investments,
      investedSum,
      investmentLimit,
      waitingListCheck,
      waitingListChecked,
      profile,
      vouchers,
      suitability,
      concentrationLimit,
      filteredVouchers,
      investVoucherFully,
      displayInvestVoucherWithoutInvestment,
      subTypeLimits
    }
  }
}
</script>

<style lang="scss" scoped></style>
