<template>
  <div
    class="page flex px-0 lg:px-0 container"
    style="padding-bottom: 0"
  >
  <div class="absolute sm:ml-0 pt-4 lg:ml-16 md:pt-14 z-50 flex w-full items-center md:items-baseline sm:w-auto justify-between md:justify-start">
    <a href="https://goparity.ca" >
      <img
        src="@/assets/img/logo/goparity.svg"
        alt=""
        class="w-28 sm:w-40 ml-4 sm:ml-0"
      />
    </a>
  </div>
    <div class="sm:relative lg:flex lg:flex-grow w-full">
      <img
        src="@/assets/img/plant-hand.png"
        alt=""
        class="ml-auto right-0 top-1/5 w-full block sm:hidden my-6"
      />
      <GeneralSlide
        class="
          lg:w-6/12 lg:m-auto lg:h-screen
          text-left
          hidden
          lg:block
          items-end
          lg:items-start
        "
        :dir="'/img/login/'"
        :data="[
          $tm(
            'login.slider.' +
              Math.floor(Math.random() * $tm('login.slider').length)
          )
        ]"
      />
      <LoginCard
        class="
          w-full
          bottom-0
          sm:h-auto
          lg:min-h-3/4 lg:min-h-auto
          sm:relative sm:absolute
          lg:w-5/12
          top-card
          sm:top-0
          h-fit
          max-w-3xl
          lg:mt-0 lg:mt-auto
          m-auto
          lg:scale-90
          transform
        "
      />
    </div>
  </div>
</template>

<script>
import LoginCard from '@/components/login/Card.vue'
import GeneralSlide from '@/components/general/Slide.vue'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import axios from "axios";
import { useRouter } from "vue-router";

export default {
  components: {
    LoginCard,
    GeneralSlide
  },
  props: {
    activated: String
  },
  setup(props) {
    const i18n = useI18n()
    const store = useStore()
    const router = useRouter()
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition)
      }
    }

    function showPosition(position) {
      axios.get('https://geocode.xyz/'+position.coords.latitude+','+position.coords.longitude+'?json=1&auth=363504834448590615537x111033', {transformRequest: (data, headers) => {
          delete headers.common['X-Authorization'];
          return data;
        }
      }).then((data)=>{
        if(data.data && data.data.country === 'Russia'){
          router.push('/unauthorized')
        }
      })
    }
    onMounted(() => {
      getLocation()

      if (props.activated === 'true') {
        const popup = {
          title: i18n.t('activate.success.title'),
          align: 'center',
          side: false,
          iconBg: 'bg-green-5',
          icon: '/img/done_step.svg',
          content: i18n.t('activate.success.description'),
          primaryButton: i18n.t('activate.success.btn')
        }
        store.dispatch('setPopup', popup)
      } else if (props.activated === 'false') {
        const popup = {
          title: i18n.t('activate.error.title'),
          align: 'center',
          side: false,
          content: i18n.t('activate.error.description'),
          primaryButton: i18n.t('activate.error.btn')
        }
        store.dispatch('setPopup', popup)
      }
    })
  }
}
</script>
